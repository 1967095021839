import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Language } from 'app/services/i18n.languages';
import { HintService } from 'app/modules/common/hint/hint.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { GrantService } from 'app/core/auth/services/grant.service';
import { SentryService } from 'app/services/sentry.service';
import { FavoritesEnum, FavoritesService } from 'app/modules/common/services/favorites.service';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { TimepickerFormatEnum, TimepickerFormatList } from 'app/modules/common/datetime/datetime.interface';
import { TicketService } from 'app/modules/common/hint/ticket.service';
import { TimeFormatService } from 'app/core/auth/services/time-format.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  //userStatusOptions: any[];
  currentUser: CurrentUserService;

  localAndNotDev: boolean;

  private _unsubscribeAll: Subject<any>;

  groupsLabel: string = '';
  rolesLabel: string = '';

  TimepickerFormatList = TimepickerFormatList;
  currentTimeFormat = this._timeFormatService.timeFormat;

  setTimeFormat(value: TimepickerFormatEnum) {
    this._timeFormatService.setTimeFormat(value);
    location.reload();
  }

  impersonateX1() {
    this._currentUserService.impersonateUser('t.smallbusiness', true);
    location.reload();
  }

  impersonateX2() {
    this._currentUserService.impersonateUser('t.settings', true);
    location.reload();
  }

  currentUrl: string;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _currentUserService: CurrentUserService,
    private _authService: AuthenticationService,
    private _router: Router,
    private _i18nService: I18nService,
    private _hintService: HintService,
    private _toastrService: ToastrService,
    public favoritesService: FavoritesService,
    private _grantService: GrantService,
    private _fuseNavigationService: FuseNavigationService,
    private _sentryService: SentryService,
    private _userRepositoryService: UserRepositoryService,
    private _activatedRoute: ActivatedRoute,
    private _ticketService: TicketService,
    private _timeFormatService: TimeFormatService,
  ) {
    this.currentUser = this._currentUserService;

    console.log('User', this.currentUser);
    console.log('_activatedRoute', this._activatedRoute);

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        console.log(this.currentUrl); // Logs the new URL after navigation
      });

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Get default navigation
    this.navigation = navigation({
      I18nService: this._i18nService,
      HintService: this._hintService,
      SentryService: this._sentryService,
      TicketService: this._ticketService,
    });

    this.localAndNotDev = !window.location.origin.includes('programmatic.fluidnext.com') && environment.baseurl.includes('programmatic.fluidnext.com') && !environment.baseurl.includes('dev');

    const roleList = this._userRepositoryService.getRoles();
    const rolesMap = roleList.reduce((acc, val) => {
      acc[val.value] = val;
      return acc;
    }, {});

    this.rolesLabel = this.currentUser.getRoles().map(role => {
      return rolesMap[role]?.label;
    }).filter(v => !!v).join(',');
  }

  get isProfilePage() {
    return this.currentUrl === '/profile';
  }

  logout(): void {
    this._authService.logout();
    location.reload();
  }

  get favorites() {
    return this.favoritesService.getFavorites(FavoritesEnum.system);
  }

  removeFavorite(event, item) {
    event.stopPropagation();
    this.favoritesService.remove(FavoritesEnum.system, item);
  }

  ngOnInit(): void {

    this.groupsLabel = this.currentUser.getGroups().map(v => v.name).join(',') || this._i18nService.translate(_('Gruppi non impostati'));

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings: any) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // console.log('currentUser', this._currentUserService);
    // console.log('isAdmin', this._currentUserService.isAdmin());

    // SIDE MENU SETTINGS BOX VISIBILITY ONLY ADMIN
    this._grantService.permissions
      .pipe(
        tap(() => {
          // console.log('PERMISSIONS CHANGED FROM TOOLBAR');
          // this._fuseNavigationService.updateNavigationItem('help-hints', {
          //   hidden: !this._currentUserService.isAdmin(),
          // });
          // this._fuseNavigationService.updateNavigationItem('ads-section', {
          //   hidden: !this._currentUserService.isAdmin(),
          // });
          // this._fuseNavigationService.updateNavigationItem('analytics-section', {
          //   hidden: !this._currentUserService.isAdmin(),
          // });
          // this._fuseNavigationService.updateNavigationItem('overview', {
          //   hidden: !this._currentUserService.isAdmin(),
          // });
          this._fuseNavigationService.updateNavigationItem('audience-section', {
            hidden: !this._currentUserService.isAdmin(),
          });
          this._fuseNavigationService.updateNavigationItem('logexport', {
            hidden: !this._currentUserService.isAdmin(),
          });
          this._fuseNavigationService.updateNavigationItem('folders', {
            hidden: !this._currentUserService.isAdmin() && !this._currentUserService.currentUser.settings.resources20,
          });
          this._fuseNavigationService.updateNavigationItem('openticketdialog', {
            hidden: !this._currentUserService.isAdmin(),
          });
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  myProfile(): void {
    this._router.navigate(['/profile']);
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get isAdminTaras() {
    return this.isAdmin && this._currentUserService.currentUser.username === 't.kushnir';
  }

  isImpersonatedUser() {
    return this._currentUserService.isImpersonatedUser();
  }

  logoutToAdminUser() {
    this._currentUserService.logoutImpersonatedUser();
  }

  toggleSidebarOpen(key): void {
    const sidebar = this._fuseSidebarService.getSidebar(key);
    if (sidebar) {
      sidebar.toggleOpen();
    } else {
      console.warn(`sidbear ${key} not registered`);
    }
  }

  search(value): void {
    // Do your search here...
    //console.log(value);
  }

  get languages(): Language[] {
    return environment.languages ? I18nService.languages || [] : [];
  }

  get currentLanguage() {
    return this._i18nService.currentLanguage;
  }

  setLanguage(lang: Language): void {
    if (lang.code !== this._i18nService.currentLanguageCode) {
      this._i18nService.setLanguage(lang.code);

      this._toastrService.success(this._i18nService.translate(_('Nuova lingua: {language}'), { language: lang.name }));

      // this._toastrService.success(this._i18nService.translate('toasts.languageChange') + ': ' + lang.name);
    }
  }
}
