<div [formGroup]="form" fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap="12px">
  <!-- RADIO -->
  <ng-container formGroupName="constraints">

    <mat-radio-group class="mb-12" formControlName="container" name="resource_container"
      (change)="onContainerChange($event)">
      <ng-container *ngFor="let c of ResourceConstraintList">
        <mat-radio-button class="pr-12" [value]="c.value">{{c.label}}</mat-radio-button>
      </ng-container>
    </mat-radio-group>

    <ng-container *ngIf="codecsList.length > 1">
      <mat-form-field class="w-120 mb-12">
        <mat-select formControlName="codec" name="resource_codec" required [multiple]="false"
          [placeholder]="'Codec' | translate" (selectionChange)="onCodecsChange($event)">
          <mat-option *ngFor="let codec of codecsList" [value]="codec">
            {{ codec }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'Obbligatorio' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

  </ng-container>


  <mat-form-field class="w-120 mb-12">
    <input matInput type="number" [min]="500" [max]="40000" name="resource_bitRateLimit" formControlName="bitRateLimit"
      [placeholder]="'Limite Bit Rate' | translate" (input)="onBitRateLimitChange()" />
    <mat-hint>Min: 500, Max: 40000</mat-hint>
    <mat-error>
      {{ 'Valore non valido' | translate }}
    </mat-error>
  </mat-form-field>

</div>

<!-- <pre>{{ form.value | json }}</pre> -->