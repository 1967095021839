import { ResourceConstraint, ResourceConstraintInterface } from 'app/modules/resources/components/resource-constraints/resource-constraints.model';
import { FolderInterface } from './folder.model';
import { Group } from './group.model';

export interface Resource {
  constraints?: ResourceConstraintInterface;
  bitRateLimit?: number;
  name: string;
  groups?: Group[];
  type?: string;
  fileinfo?: Fileinfo;
  source?: string;
  notPrivate?: boolean;
  status?: string;
  id?: string;
  created_at?: Date;
  updated_at?: Date;
  metadata?: Metadata;
  transcodedMetadata?: TranscodedMetadata;
  transcodingProgress?: number;
  head?: Head;
  webReady?: boolean;
  transcodedSource?: string;
  webSource?: string;
  publicSource?: string;
  publicTranscodedSource?: string;
  publicWebSource?: string;
  publicAvailableSource?: string;
  thumb?: string;
  folder?: FolderInterface;
}

export interface ResourceTranscodeRequestInterface {
  constraints?: ResourceConstraintInterface;
  bitRateLimit?: number;
}

export class ResourceTranscodeRequest implements ResourceTranscodeRequestInterface {
  constraints?: ResourceConstraintInterface;
  bitRateLimit?: number;

  constructor(private _conf: ResourceTranscodeRequestInterface) {
    Object.assign(this, this._conf);
    this.constraints = new ResourceConstraint(this.constraints);
    // if (!this.bitRateLimit) {
    //   this.bitRateLimit = 10_000;
    // }
    delete this._conf;
  }
}

export enum ResourceTypeEnum {
  images = 'images',
  videos = 'videos',
}

export const RESOURCE_TYPE = {
  images: 'images',
  videos: 'videos',
  image: 'image',
  video: 'video',
};

export interface Head {
  checksum?: string;
  size?: number;
  updated_at?: string;
}

export interface Metadata {
  container?: string;
  contentType?: string;
  videoStream?: number;
  type?: string;
  codec?: string;
  duration?: string | number;
  bit_rate?: string;
  width?: number;
  height?: number;
  fps?: number;
  allStreams?: AllStream[];
}

export interface TranscodedMetadata {
  bit_rate?: string;
  size?: number;
  format?: string;
  video_codec?: string;
}

interface AllStream {
  index: number;
  type: string;
  codec: string;
  duration: string;
  bit_rate: string;
  width: number;
  height: number;
  fps: number;
}

export interface Fileinfo {
  dirname: string;
  basename: string;
  extension: string;
  filename: string;
}

// export class ResourceStatus {
//   public static USABLE = 'usable';
//   public static ANALYZE = 'analyze';
//   public static FRAMING = 'framing';
//   public static TRANSCODING = 'transcoding';
//   public static RESIZING = 'resizing';
//   public static ERROR = 'error';
// }

export enum ResourceStatus {
  USABLE = 'usable',
  ANALYZE = 'analyze',
  FRAMING = 'framing',
  TRANSCODING = 'transcoding',
  RESIZING = 'resizing',
  ERROR = 'error',
}


export interface ResourcesStatsByCustomerInterface {
  totalCount?: number;
  totalSize?: number;
}
