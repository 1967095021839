import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { DistributionInterface } from 'app/models/distribution.model';
import { PlaylistItemNestedInterface, PlaylistItemInterface } from 'app/modules/editorial/editorial.interface';
import {
  WeeklySchedulingInterface,
  TimeslotInterface,
  TimeslotsCalendarInterface,
} from 'app/modules/common/timeslot/timeslot.interface';
import { Resource } from 'app/models/resource.model';
import { DateRangeInterface } from 'app/modules/common/datetime/dateslots/dateslot.interface';
import { DatasetInterface } from 'app/models/dataset.model';
import { FluidDatePipe } from 'app/modules/global/pipes/fluidDate.pipe';

@Injectable({
  providedIn: 'root',
})
export class DistributionDataTransformDown extends DataTransformAbstractDown {

  constructor(protected _fluidDatePipe: FluidDatePipe) {
    super();
  }
  /**
   *
   *
   * @param {DistributionInterface} distribution
   * @returns
   * @memberof DistributionDataTransform
   */
  transform(distribution: DistributionInterface): DistributionInterface {
    if (distribution.startAt) {
      distribution.startAt = this.normalizeDate(distribution.startAt.toString());
    }
    if (distribution.endAt) {
      distribution.endAt = this.normalizeDate(distribution.endAt.toString());
    }
    this.normalizeResources(distribution);
    return distribution;
  }

  /**
   *
   *
   * @private
   * @param {DistributionEditorial} editorial
   * @returns
   * @memberof DistributionDataTransformUp
   */
  private normalizeResources(distribution: DistributionInterface) {
    if (!Array.isArray(distribution.resources)) {
      return this;
    }
    distribution.resources.map((resourceInt: PlaylistItemInterface) => {
      resourceInt.timeslots = this.normalizeTimeslots(resourceInt.timeslots);
      resourceInt.weeklyScheduling = this.normalizeWeeklySchedulings(resourceInt.weeklyScheduling);
      resourceInt.calendarScheduling = this.calendarSchedulings(resourceInt.calendarScheduling);

      if (resourceInt.resource) {
        resourceInt.resource = <Resource>{ id: resourceInt.resource.id };
      }
      if (resourceInt.dcontent) {
        resourceInt.dcontent = <DatasetInterface>{ id: resourceInt.dcontent.id };
      }
      this.normalizeNested(resourceInt.nested);
    });
    return this;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotsCalendar[]} calendarScheduling
   * @returns {TimeslotsCalendar[]}
   * @memberof SystemDataTransformDown
   */
  protected calendarSchedulings(calendarScheduling: TimeslotsCalendarInterface[]): TimeslotsCalendarInterface[] {
    if (!Array.isArray(calendarScheduling)) {
      return calendarScheduling;
    }
    calendarScheduling.map((calendar) => {
      if (calendar.startAt) {
        calendar.startAt = this.normalizeDate(calendar.startAt.toString());
      }
      if (calendar.endAt) {
        calendar.endAt = this.normalizeDate(calendar.endAt.toString());
      }
      calendar.weeklySchedulings = this.normalizeWeeklySchedulings(calendar.weeklySchedulings);
      calendar.timeslots = this.normalizeTimeslots(calendar.timeslots);
    });
    return calendarScheduling;
  }

  normalizeNested(nested: PlaylistItemNestedInterface) {
    if (nested) {
      if (!Array.isArray(nested.resources)) {
        return this;
      }
      nested.resources.map((resourceInt: PlaylistItemInterface) => {
        // this.normalizeRange(resourceInt.range);
        // THIS SHOULDN'T EXIST IN NESTED RESOURCES
        this.normalizeTimeslots(resourceInt.timeslots);
        if (resourceInt.resource) {
          resourceInt.resource = <Resource>{ id: resourceInt.resource.id };
        }
        if (resourceInt.dcontent) {
          resourceInt.dcontent = <DatasetInterface>{ id: resourceInt.dcontent.id };
        }
      });
    }
  }

  normalizeRange(range: DateRangeInterface[]) {
    if (!Array.isArray(range)) {
      return this;
    }
    range.map((rangeItem: DateRangeInterface) => {
      if (rangeItem.startAt) {
        rangeItem.startAt = this.normalizeDate(rangeItem.startAt.toString());
      }
      if (rangeItem.endAt) {
        rangeItem.endAt = this.normalizeDate(rangeItem.endAt.toString());
      }
    });
  }

  /**
   *
   *
   * @protected
   * @param {WeeklyScheduling[]} weeklyScheduling
   * @returns {WeeklyScheduling[]}
   * @memberof DistributionDataTransformDown
   */
  protected normalizeWeeklySchedulings(weeklyScheduling: WeeklySchedulingInterface[]): WeeklySchedulingInterface[] {
    if (!Array.isArray(weeklyScheduling)) {
      return weeklyScheduling;
    }
    weeklyScheduling.map((day) => {
      day.timeslots = this.normalizeTimeslots(day.timeslots);
    });
    return weeklyScheduling;
  }
  /**
   *
   *
   * @protected
   * @param {Timeslot[]} timeslots
   * @returns {Timeslot[]}
   * @memberof DistributionDataTransformDown
   */
  protected normalizeTimeslots(timeslots: TimeslotInterface[]): TimeslotInterface[] {
    timeslots = timeslots || [];
    timeslots.map((timeslot: TimeslotInterface) => {
      this.normalizeTimeslot(timeslot);
    });
    return timeslots;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotInterface} timeslot
   * @returns {TimeslotInterface}
   * @memberof DistributionDataTransformDown
   */
  protected normalizeTimeslot(timeslot: TimeslotInterface): TimeslotInterface {
    if (timeslot.from) {
      timeslot.from = this.normalizeTime(timeslot.from.toString());
    }
    if (timeslot.to) {
      timeslot.to = this.normalizeTime(timeslot.to.toString());
    }
    return timeslot;
  }
}
