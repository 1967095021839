<ng-template #defaultActions let-item="item" let-component="component">
</ng-template>

<mat-form-field fxFlex="100">
  <mat-chip-list #chipList aria-label="Tag selection" [required]="required" (click)="onClick($event)">
    <mat-chip *ngFor="let item of decorateValues(values); let index = index" [removable]="removable"
      [disabled]="disabled" (removed)="remove(decorate(item), index)">
      <span class="ellipsis" style="max-width: 100%;">
        <ng-container *ngIf="autocompleteLabel?.template">
          <ng-container *ngTemplateOutlet="autocompleteLabel?.template; context: { item: item }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!autocompleteLabel?.template">
          <ng-container *ngIf="!!translate(item)">
            {{ translate(item) }}
          </ng-container>
          <ng-container *ngIf="!translate(item)">
            <span class="red-400-fg text-barred font-weight-400">{{ 'sconosciuto' | translate }}</span>
          </ng-container>
        </ng-container>
      </span>
      <!-- ADDITIONAL ACTIONS -->
      <ng-container
        *ngTemplateOutlet="component.actionsTpl ? component.actionsTpl : defaultActions; context: { item: item, index: index, component: component }"></ng-container>
      <!-- REMOVE -->
      <mat-icon matChipRemove *ngIf="!disabled && removable" class="ml-4">cancel</mat-icon>
    </mat-chip>
    <input #autocompleteInput [placeholder]="placeholder" [formControl]="autocompleteControl"
      [disableControl]="disabled" [readonly]="disabled" [matAutocompleteDisabled]="disabled" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
      (matChipInputTokenEnd)="optionCreated($event)" (blur)="blur($event)" />
  </mat-chip-list>
  <mat-icon matSuffix *ngIf="loading" class="app-icon-xs app-rotate">autorenew</mat-icon>
  <button *ngIf="!disabled && (searchValue?.length > 0 || values?.length > 0) && !loading" matSuffix class="clearable"
    mat-icon-button (click)="reset()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete [autoActiveFirstOption]="autoActiveFirstOption" #auto="matAutocomplete"
    (optionSelected)="optionSelected($event)">
    <mat-option *ngFor="let item of filteredItems; let index = index" [value]="decorate(item)">
      <ng-container *ngIf="autocompleteLabel?.template">
        <ng-container
          *ngTemplateOutlet="autocompleteLabel?.template; context: { item: item, index: index }"></ng-container>
      </ng-container>
      <ng-container *ngIf="!autocompleteLabel?.template">
        {{ translate(item) }}
      </ng-container>
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>{{ 'Obbligatorio' | translate }} </mat-error>
</mat-form-field>

<!-- <pre>Disabled: {{ disabled }}</pre> -->