import { Component, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter, forwardRef, Injector, OnInit, SimpleChanges, HostBinding } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, Validator, Validators } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TimepickerFormatEnum } from '../datetime.interface';
import { TiemepickerInputService } from '../timepicker-input/timepicker-input.service';
import { I18nService } from 'app/services/i18n.service';
import { TimeFormatService } from 'app/core/auth/services/time-format.service';

/**
 *
 *
 * @export
 * @class TimepickerComponent
 * @description Timepicker input form field component
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ]
})
export class TimepickerComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() control: FormControl;

  @Input() placeholder: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() date: Date;
  @Input() nullable: boolean;
  @Input() hasSeconds: boolean = false;

  // requiredValidator = Validators.required;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);

    // if (value) {
    //   this.ngControl?.control?.addValidators(this.requiredValidator);
    // } else {
    //   this.ngControl?.control?.removeValidators(this.requiredValidator);
    // }
  }
  private _required = false;


  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;


  @Input() hint: string;
  @Input() format: TimepickerFormatEnum = this._timeFormatService.timeFormat;

  get isH12() {
    return this.format === TimepickerFormatEnum.h12;
  }

  @Output() enterKey: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private _cdr: ChangeDetectorRef,
    private injector: Injector,
    private _tiemepickerInputService: TiemepickerInputService,
    private _i18nService: I18nService,
    private _timeFormatService: TimeFormatService,
  ) { }

  ngControl: NgControl;
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
  }

  get formControl() {
    return (this.ngControl as any)?.control || this.control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      // (changes['required'] && !changes['required'].firstChange) ||
      // (changes['disabled'] && !changes['disabled'].firstChange) ||
      // (changes['date'] && !changes['date'].firstChange) ||
      (changes['min'] && !changes['min'].firstChange) ||
      (changes['max'] && !changes['max'].firstChange)
    ) {
      // console.log('ngOnChanges min max trigger validation', changes);
      if (this.ngControl && this.ngControl.control) {
        if (this.ngControl.hasError('max') && !this.max) {
          // console.log('REMOVE MAX ERROR');
          this.ngControl?.control?.updateValueAndValidity();
        }
        if (this.ngControl.hasError('min') && !this.min) {
          // console.log('REMOVE MIN ERROR');
          this.ngControl?.control?.updateValueAndValidity();
        }
      }
    }
    // console.log('TimepickerComponent changes', 'min:', this.min, 'value:', this.control.value);
  }

  valueChangedEvent(value) {
    this.valueChanged.emit(value);
  }

  onCleared(event) {
    this.valueChanged.emit(null);
  }

  private _value;
  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(newValue) {
    if (typeof newValue !== 'undefined') {
      this._value = newValue;
    }
  }

  validate(c: FormControl) {
    // console.log('validate mycustompicker', c.value);

    if (this.disabled) {
      return null;
    }

    const required = this.required && Validators.required(c);
    const min = this._tiemepickerInputService.min(this.min, this.date)(c);
    const max = this._tiemepickerInputService.max(this.max, this.date)(c);

    // console.log('validate timepicker', c.value, this.max, required, min, max);

    const errors = required || min || max || null;

    return errors;
  }

}
