import { GroupInterface } from 'app/models/group.model';
import { BrandInterface } from 'app/models/brand.model';
import { ScreenInterface } from 'app/modules/system/components/screens/screens.interface';
import { GeoindoorInterface } from './geoindoor.model';
import { EditorialStandAloneInterface, EditorialInterface, PlaylistInterface } from './editorial.model';
import { Resource } from './resource.model';
import { LicenseInterface } from './license.model';
import { Tag } from './tag.model';
import { CustomerInterface } from './customer.model';
import { Geo, GeoInterface } from 'app/modules/common/inputs/address/address.component';
import {
  TimeslotInterface,
  WeeklySchedulingInterface,
  BrightnessSchedulingInterface,
  TimeslotsCalendarInterface,
} from 'app/modules/common/timeslot/timeslot.interface';
import { ListOptionInterface } from 'app/modules/common/inputs/inputs.interface';
import { CircuitInterface } from './circuit.model';
import {
  PlayerSettings,
  PlayerSettingsInterface,
} from 'app/modules/common/system/components/system-player-settings/system-player-settings.model';
import { SchedulingEnum } from './scheduling.model';
import { UpdatedByInterface } from './update-by.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface PlayerInfoInterface {
  created_at?: Date;
  lastPing?: Date;
  log_status?: boolean;
}

export interface WatcherInterface {
  ping?: string | Date;
  version?: string;
}

export interface MonitorInterface {
  schedulingType?: SchedulingEnum; // none | daily | weekly
  playerPing?: string | Date;
  playerVersion?: string;
  incident?: boolean;
  incidentSeverity?: boolean;
  unmounted?: boolean;
  inactive?: boolean;
  notInstalled?: boolean;
  range?: TimeslotInterface; // daily
  weeklyScheduling?: WeeklySchedulingInterface[]; // weekly
  os?: OSInterface;
  teamviewerId?: string;
  teamviewerGuid?: string;
  teamviewerDeviceId?: string;
  warningCount?: number;
  incidentDate?: Date;
}

export interface OSInterface {
  arch?: string;
  nCpus?: number;
  freemem?: number;
  totalmem?: number;
  usedmem?: number;
  hostname?: string;
  platform?: string;
  uptime?: number;
  uptime_player?: number;
  type?: string;
  pid?: number;
  mac_address?: string;
  tz?: string;
  offset?: number;
  // @deprecated
  timezone?: number;
}

export interface LogSettingsInterface {
  expire?: number;
}

export class Monitor implements MonitorInterface {
  playerPing: string | Date;
  incident: boolean;
  playerVersion: string;
  range: TimeslotInterface;
  schedulingType?: SchedulingEnum = SchedulingEnum.none;
  weeklyScheduling?: WeeklySchedulingInterface[] = [];
  unmounted?: boolean;
  inactive?: boolean;
  notInstalled?: boolean;
  warningCount?: number = 0;
  incidentDate?: Date;


  /**
   * Constructor
   *
   * @param _monitor
   */
  constructor(private _monitor?: MonitorInterface) {
    Object.assign(this, this._monitor);
    delete this._monitor;
  }
}

export interface SystemHealthInterface {
  connection: number;
  updated_at: Date;
}

export class SystemHealth implements SystemHealthInterface {
  connection: number = 0;
  updated_at: Date;

  /**
   * Constructor
   *
   * @param _monitor
   */
  constructor(private _monitor?: SystemHealthInterface) {
    Object.assign(this, this._monitor);
    delete this._monitor;
  }
}

export interface SystemInterface {
  id?: string;
  token?: string;
  type?: string;
  name?: string;
  description?: string;
  grant?: string;
  groups?: GroupInterface[];
  range?: TimeslotInterface;
  schedulingType?: SchedulingEnum;
  weeklyScheduling?: WeeklySchedulingInterface[];
  calendarScheduling?: TimeslotsCalendarInterface[];
  brightnessScheduling?: BrightnessSchedulingInterface[];
  timeframes?: any[];
  brand?: BrandInterface;
  location?: any;
  department?: any;
  geoindoor?: GeoindoorInterface;
  created_at?: Date | string;
  updated_at?: Date | string;
  updated_by?: UpdatedByInterface;
  archived?: boolean;
  archivedAt?: Date | string;
  n_packages?: number;
  code?: string;
  width?: number;
  height?: number;
  edtPerc?: number;
  enableUpdatePlayer?: boolean;
  editorialsFraction?: boolean;
  editorials?: EditorialInterface[];
  standAlone?: EditorialStandAloneInterface;
  playlists?: PlaylistInterface[];
  packages?: any[];
  sellable?: boolean;
  alwaysOn?: boolean;
  screens?: ScreenInterface[];
  player_info?: PlayerInfoInterface;
  geo?: GeoInterface;
  watcher?: WatcherInterface;
  monitor?: MonitorInterface;
  logSettings?: LogSettingsInterface;
  point_id?: string;
  on?: string | Date;
  off?: string | Date;
  startAt?: string | Date;
  endAt?: string | Date;
  mainPhoto?: Resource;
  clip?: Resource;
  photos?: Resource[];
  customer?: CustomerInterface;
  use?: string; // license, demo, fee, free
  license?: LicenseInterface;
  licenseRentalExpireDate?: Date | string;
  tags?: Tag[];
  envs?: ListOptionInterface[];
  special?: boolean;
  specification?: SystemSpecificationInterface;
  firmware_status?: string;
  firmware_status_timestamp?: string | Date;
  playerSettings?: PlayerSettingsInterface;
  circuits?: CircuitInterface[];
  analytics?: SystemAnalyticsInterface;
  linked?: boolean;
  systemHealth?: SystemHealthInterface;
}

export class System implements SystemInterface {
  id: string;
  token: string;
  type: string;
  name: string;
  description: string;
  grant: string = 'permanent';
  groups: GroupInterface[] = [];
  range: any;
  schedulingType: SchedulingEnum;
  weeklyScheduling: WeeklySchedulingInterface[] = [];
  calendarScheduling: TimeslotsCalendarInterface[] = [];
  brightnessScheduling: BrightnessSchedulingInterface[] = [];
  timeframes: any[] = [];
  brand: BrandInterface;
  location: any;
  department: any;
  geoindoor: GeoindoorInterface;
  created_at: Date;
  updated_at: Date;
  updated_by: UpdatedByInterface;
  archived: boolean;
  archivedAt: Date | string;
  n_packages: number;
  code: string;
  width: number;
  height: number;
  edtPerc: number;
  enableUpdatePlayer: boolean = true;
  editorialsFraction: boolean;
  editorials: EditorialInterface[] = [];
  standAlone: EditorialStandAloneInterface;
  playlists: PlaylistInterface[] = [];
  packages: any[] = [];
  sellable: boolean = true;
  alwaysOn: boolean = true;
  screens: ScreenInterface[] = [];
  player_info: PlayerInfoInterface;
  geo: GeoInterface = null;
  //  {
  //   formatted_address: "Via Francesco Olgiati, 5, Milano, MI, Italia",
  //   place_id: "ChIJyZVT6rjDhkcRNm-mY1n6QuM",
  //   icon: "",
  //   lat: 45.4412131,
  //   lng: 9.1455418
  // };
  watcher: WatcherInterface;
  monitor: MonitorInterface;
  logSettings: LogSettingsInterface = { expire: 7 };
  point_id: string;
  on: string | Date;
  off: string | Date;
  startAt: string | Date;
  endAt: string | Date;
  mainPhoto: Resource;
  clip: Resource;
  photos: Resource[] = [];
  customer: CustomerInterface;
  use: string = SYSTEM_USE.demo; // license, demo, fee, free
  license: LicenseInterface;
  licenseRentalExpireDate: Date | string;
  tags: Tag[] = [];
  envs: ListOptionInterface[] = [];
  special: boolean = true;
  specification: SystemSpecificationInterface;
  firmware_status: string;
  playerSettings: PlayerSettingsInterface;
  analytics: SystemAnalyticsInterface;
  linked: boolean = false;
  systemHealth: SystemHealthInterface;

  /**
   * Constructor
   *
   * @param _system
   */
  constructor(private _system?: SystemInterface) {
    Object.assign(this, this._system);
    this.monitor = new Monitor(this.monitor);
    this.specification = new SystemSpecification(this.specification);
    this.logSettings = new LogSettings(this.logSettings);
    this.playerSettings = new PlayerSettings(this.playerSettings);
    this.analytics = new SystemAnalytics(this.analytics);
    this.systemHealth = new SystemHealth(this.systemHealth);
    // this.geo = new Geo(this.geo);
    if (this.licenseRentalExpireDate) {
      this.licenseRentalExpireDate = new Date(this.licenseRentalExpireDate);
    }
    delete this._system;
  }
}

export interface SystemAnalyticsInterface {
  enabled?: boolean;
}

export class SystemAnalytics implements SystemAnalyticsInterface {
  enabled: boolean = false;

  constructor(private _conf: SystemAnalyticsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export const SYSTEM_USE = {
  demo: 'demo',
  license: 'license',
  fee: 'fee',
  free: 'free',
  rental: 'rental',
};

export interface SystemSpecificationInterface {
  kind?: string; // digital | analogic
  indoor?: boolean;
  outdoor?: boolean;
  touch?: boolean;
}

export class SystemSpecification implements SystemSpecificationInterface {
  kind?: string = 'digital';
  indoor?: boolean;
  outdoor?: boolean;
  touch?: boolean;
  constructor(private _conf: SystemSpecificationInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class LogSettings implements LogSettingsInterface {
  expire?: number = 7;
  constructor(private _conf: LogSettingsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class OverbookingInterface {
  system: SystemInterface;
  updated_at: string | Date;
}

export const SuggestedEnvsKeys = [
  'pladway_placement_code',
  'pladway_token',
  'hivestack_apikey',
  'framen_token',
];

export const SuggestedResolutionsSizes = [
  1920,
  1080,
  5760,
  3840,
  2160,
  1280,
  1024,
  960,
  768,
  512,
  350,
];

export interface SystemChunkInterface {
  callIndex: number;
  totalCalls: number;
  currentItems: number;
  totalItems: number;
  partials: any[];
  isLast: boolean;
}

export enum SystemAlertSeverityEnum {
  unavailable = 'unavailable',
  unknown = 'unknown',
  low = 'low',
  high = 'high',
  tv_id_not_found = 'tv_id_not_found',
}

export enum SystemAlertStatusEnum {
  resolved = 'resolved',
}

export interface SystemAlertInterface {
  id: string;
  message: string;
  severity: SystemAlertSeverityEnum;
  solved_at: string | Date;
  status: SystemAlertStatusEnum;
  time: string | Date;
}

export interface SystemScreenshotInteface {
  id?: string;
  uri: string;
  created_at: string | Date;
  updated_at: string | Date;
  timestamp: string | Date;
}

export enum SystemWarningCodeEnum {
  'memHigh' = 'mem-high',
  'cpuHigh' = 'cpu-high',
  'dcOffline' = 'dc-offline',
  'wrongTime' = 'wrong-time',
  'updateOver' = 'update-over',
  'dspFailRequest' = 'dsp-failrequest',
  'turnedOnOver' = 'turnedon-over',
}

export const SystemWarningsList = [
  {
    icon: 'developer_board',
    value: SystemWarningCodeEnum.memHigh,
    label: _('Utilizzo RAM'),
    public: true,
  },
  {
    icon: 'memory',
    value: SystemWarningCodeEnum.cpuHigh,
    label: _('Utilizzo CPU'),
    public: true,
  },
  {
    icon: 'wifi_off',
    value: SystemWarningCodeEnum.dcOffline,
    label: _('Display Control non raggiungibile'),
    public: true,
  },
  {
    icon: 'history_toggle_off',
    value: SystemWarningCodeEnum.wrongTime,
    label: _('Orario macchina errato'),
    public: true,
  },
  {
    icon: 'sync_problem',
    value: SystemWarningCodeEnum.updateOver,
    label: _('Troppi aggiornamenti consecutivi'),
    public: false,
  },
  {
    icon: 'error',
    value: SystemWarningCodeEnum.dspFailRequest,
    label: _('Troppe richieste DSP fallite'),
    public: true,
  },
  {
    icon: 'pending_actions',
    value: SystemWarningCodeEnum.turnedOnOver,
    label: _('Player acceso per tanti giorni consecutivi'),
    public: true,
  },
];

export interface SystemWarningInterface {
  id: string;
  created_at: string | Date;
  updated_at: string | Date;
  closed_at: string | Date;
  status: SystemWarningStatusEnum;
  code: SystemWarningCodeEnum;
  payload: any;
  counterApiRequest: number;
}

export enum SystemWarningStatusEnum {
  open = 'open',
  close = 'close',
}
