import { Resource } from 'app/models/resource.model';
import { ResourceDialogPickerConfig } from './resource-dialog-config';
import { ResourcesPickerComponent } from 'app/modules/resources/components/resources-picker/resources-picker.component';
import { Directive, OnInit, OnChanges, Output, Input, HostListener, EventEmitter, forwardRef } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {
  ScreenFitConstrain,
  ResolutionInterface,
  ScreenInterface,
} from 'app/modules/system/components/screens/screens.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScreensService } from 'app/modules/system/components/screens/screens.service';
import { FolderService } from 'app/modules/folder/folder.service';
import { FolderResourcesSelectorDialogComponent, FolderResourcesSelectorDialogDataInterface } from 'app/modules/folder/components/dialogs/folder-resources-selector-dialog/folder-resources-selector-dialog.component';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { LS_RESOURCES_SELECTOR_VERSION } from 'app/modules/global/localstorage';
import { ResourceDialogService } from '../../services/resource.dialog.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

@Directive({
  selector: '[resourceDialog]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResourceDialogSelectorDirective),
      multi: true,
    },
  ],
})
export class ResourceDialogSelectorDirective implements OnInit, OnChanges, ControlValueAccessor {
  dialogRef: MatDialogRef<ResourcesPickerComponent | FolderResourcesSelectorDialogComponent>;
  @Output() selected: EventEmitter<Resource | Resource[]> = new EventEmitter<Resource | Resource[]>();

  @Input() set screen(screen: ScreenInterface) {
    // console.log('set screen cont dinam');
    if (!this.screens && screen && screen.fit && Array.isArray(screen.fit_constrains) && screen.fit_constrains.length) {
      this.screens = screen.fit_constrains;

      const _resKey = (fitItem: ResolutionInterface) => fitItem.width + 'x' + fitItem.height;

      const defaultResolution = this.screens.find((v: ResolutionInterface) => {
        return _resKey(screen) === _resKey(v);
      });

      if (!defaultResolution) {
        this.screens.unshift({ width: screen.width, height: screen.height });
      }
    } else {
      this.width = screen && !screen.fit ? screen.width : '*';
      this.height = screen && !screen.fit ? screen.height : '*';
    }
  }

  @Input() screens: ScreenInterface[];
  @Input() types: ('images' | 'videos')[]; // [] --> all

  @Input() width: any;
  @Input() height: any;

  @Input() multi: boolean = false;
  @Input() dynamic: boolean = false;
  @Input() replace: boolean = false;
  @Input() preventDefault: boolean = true;
  @Input() stopPropagation: boolean = true;
  @Input() excludedIdsMap: { [key: string]: number };

  private _value: Resource & Resource[];

  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  constructor(
    private _screensService: ScreensService,
    private _folderService: FolderService,
    private _cachedParamsService: CachedParamsService,
    private _resourceDialogService: ResourceDialogService,
    private _currentUserService: CurrentUserService,
  ) { }

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(value) {
    if (typeof value !== 'undefined') {
      this._value = value;
    }
  }

  ngOnInit() { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get canUserNewFolderSelector() {
    return this.isAdmin || this._currentUserService.currentUser.settings.resources20;
  }

  ngOnChanges(changes) {
    // console.log('[resourceDialog] changes', this, changes, this.width, this.height);
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    // console.log('this.ResourceDirecive', this);

    const config = new ResourceDialogPickerConfig(this.width, this.height);
    config.setMulti(this.multi);
    config.setDynamic(this.dynamic);

    let filteredScreens = this.screens;
    if (Array.isArray(this.screens) && this.screens.length > 0) {
      filteredScreens = this._screensService.getUniqueAvailableResolutions(this.screens);
    }
    config.setScreens(filteredScreens);

    config.setTypes(this.types);
    config.setExcludedIdsMap(this.excludedIdsMap);

    console.log('setScreens', this.screens, filteredScreens);

    if (this.preventDefault) {
      event.preventDefault();
    }

    if (this.stopPropagation) {
      event.stopPropagation();
    }

    console.log('resourceDialog config', config);


    if (!config.screens && this.width && this.height) {
      if (this.width !== '*' && this.height !== '*') {
        config.screens = [{
          width: this.width,
          height: this.height,
        }];
      } else {
        config.screens = [];
      }
    }

    const cachedParams = this.isAdmin ? this._cachedParamsService.getCachedParams(LS_RESOURCES_SELECTOR_VERSION) : { version: this.canUserNewFolderSelector ? 1 : 0 };

    console.log('SELECTOR cachedParams', cachedParams)

    switch (true) {
      case (this.dynamic || !cachedParams.version): { // 0
        this.dialogRef = this._resourceDialogService.open(config);
        break;
      }
      case cachedParams.version === 1: {
        const realConfig: FolderResourcesSelectorDialogDataInterface = {
          model: {
            types: config.types,
          },
          options: {
            // HARDCODE
            allowUpload: true,
            // OPTIONS
            allowedSizes: config.screens,
            allowedTypes: config.types,
            multiple: config.multi,
            excludedIdsMap: config.excludedIdsMap,
          }
        };
        this.dialogRef = this._folderService.openFolderResourcesSelectorDialog(realConfig);
        break;
      }
    }

    this.dialogRef.afterClosed().subscribe((resources: Resource[]) => {
      // console.log('after closed resources dialog...', resources);
      if (Array.isArray(resources) && resources.length) {
        let response;
        this._onTouched();
        if (this.multi) {
          if (this.replace) {
            response = resources;
          } else {
            response = (this._value || []).concat(resources);
          }
        } else {
          response = resources[0];
        }
        this._onChange(response);
        this.selected.emit(response);
      }
    });
  }
}
