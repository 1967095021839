<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo">
    <img class="logo-icon" src="assets/images/logos/fluid-logo.png">
    <img class="logo-icon logo-icon-small" src="assets/images/logos/fluid-logo-icon.png">
    <!-- <span class="logo-text secondary-text">FUSE</span> -->
  </div>

  <div class="buttons">
    <!--
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button> -->

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>

  </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <div class="user pt-8" *ngIf="currentUserService?.currentUser" fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="app-clock-timezone px-24 w-100-p pb-12" fxFlex="100" fxLayout="row"
      fxLayoutAlign="space-between center">
      <div class="grey-400-fg" fxLayout="row" fxLayoutAlign="flex-start center"><mat-icon
          class="mr-4 app-icon-xs">schedule</mat-icon>
        {{ time | fluidDate : 'mediumTime' }}
      </div>
      <small class="grey-400-fg">{{ timeZone }}</small>
    </div>

    <div class="h3 username mt-12 text-uppercase">{{currentUserService.getAnyName()}}</div>
    <div class="h5 email hint-text mt-8 text-lowercase">{{currentUserService.currentUser.email}}</div>


    <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
      <img class="avatar" [src]="currentUserService.getAvatarUrl()">
    </div>

  </div>

  <div class="navbar-content">
    <fuse-navigation layout="vertical"></fuse-navigation>

    <div class="text-right mt-24 mb-24 mr-24">
      <img src="assets/images/positivessl_trust_seal_lg_120.png" width="120" />
    </div>
  </div>


</div>