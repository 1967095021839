import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { addMonths } from 'date-fns/esm';
import { addDays, addHours, endOfDay, startOfDay, startOfWeek, startOfYear } from 'date-fns';
import { FluidDatePipe } from 'app/modules/global/pipes/fluidDate.pipe';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_MONTH = ONE_DAY * 30;
export const ONE_YEAR = ONE_MONTH * 12;
export const DAYS_IN_WEEK = 7;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  firstDayOfWeekIndex: number = 1; // { 0: Sunday, ... 6: Saturday } weeks starts on `1: Monday` in Italy

  constructor(
    private _fluidDatePipe: FluidDatePipe,
    private _datePipe: DatePipe,
  ) { }

  static isDate(date: Date | string | number) {
    return !isNaN(new Date(date).getDate());
  }

  static get today(): Date {
    return this.parse();
  }

  static parse(date: Date | string | number = new Date()): Date {
    if (this.isDate(date)) {
      return new Date(date);
    }
    return new Date();
  }

  static toFluidDayIndex(index) {
    return index === 0 ? 7 : index;
  }

  static dayIndex(date = this.today) {
    date = this.parse(date);
    return date.getDay();
  }

  static isRangeInRange(A, B, from, to): boolean {
    if ((!B || B > from) && (!A || A < to)) {
      return true;
    }
    return false;
  }

  static dateLabel(date = this.today) {
    date = this.parse(date);
    return new DatePipe('it').transform(date, 'EEEE');
  }

  static dateLetter(date = this.today) {
    date = this.parse(date);
    return this.dateLabel(date).substring(0, 1);
  }

  static dateInfo(date: Date | string = this.today) {
    date = this.parse(date);
    const dayInfo: DateInfoInterface = {
      weekday: this.toFluidDayIndex(this.dayIndex(date)),
      date: date,
      label: this.dateLabel(date),
      letter: this.dateLetter(date),
    };
    return dayInfo;
  }

  get today(): Date {
    return this.parse();
  }

  // normalize weekday index from Fluid to JS (Sunday --> #0)
  fromFluidDayIndex(index) {
    return index === 7 ? 0 : index;
  }

  // normalize JS date index `.getDay()` to Fluid (Sunday --> #7)
  toFluidDayIndex(index) {
    return index === 0 ? 7 : index;
  }

  dayIndex(date = this.today) {
    date = this.parse(date);
    return date.getDay();
  }

  isDate(date) {
    return DateService.isDate(date);
  }

  isRangeInRange(A, B, from, to): boolean {
    if ((!B || B > from) && (!A || A < to)) {
      return true;
    }
    return false;
  }

  isInTimeRange(date, from, to, accurate = false): boolean {
    date = this.parse(date);
    if (from) {
      from = this.copyTime(from, date, accurate);
    }
    if (to) {
      to = this.copyTime(to, date, accurate);
    }
    // console.log('date', date, 'from', from, 'to', to);
    if ((!from || date >= from) && (!to || date <= to)) {
      return true;
    }
    return false;
  }

  parse(date: any = new Date()): Date {
    return DateService.parse(date);
  }

  copyTime(date, target = this.today, accurate = false) {
    date = this.parse(date);
    target = this.parse(target);
    if (!accurate) {
      target.setHours(date.getHours(), date.getMinutes(), 0, 0);
    } else {
      target.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }
    return target;
  }

  sameDateTime(date1, date2 = this.today) {
    date1 = this.parse(date1);
    date2 = this.parse(date2);
    return date1.getTime() === date2.getTime();
  }

  sameDate(date1, date2 = this.today) {
    date1 = this.parse(date1);
    date2 = this.parse(date2);
    return date1.toDateString() === date2.toDateString();
  }

  sameTime(date1, date2 = this.today) {
    date1 = this.parse(date1);
    date2 = this.parse(date2);
    return date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes();
  }

  isToday(date = this.today) {
    return this.sameDate(date, this.today);
  }

  numberOfDays(date1: Date | string, date2: Date | string, allowSameDay: boolean = false) {
    if (date1 && date2) {
      if (allowSameDay) {
        date1 = startOfDay(this.parse(date1));
        date2 = endOfDay(this.parse(date2));
      } else {
        date1 = this.parse(date1);
        date2 = this.parse(date2);
      }
      return Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / ONE_DAY);
    }
    return 0;
  }

  firstDayOfMonth(date: string | Date = this.today) {
    date = this.parse(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  firstDayOfWeek(date = this.today, firstDayOfWeekIndex = this.firstDayOfWeekIndex): Date {
    date = startOfDay(date);
    let currentDayIndex = this.dayIndex(date);
    if (currentDayIndex < firstDayOfWeekIndex) {
      currentDayIndex = DAYS_IN_WEEK - currentDayIndex;
    }
    const daysDiff = currentDayIndex - firstDayOfWeekIndex;
    return this.addDays(-daysDiff, date);
  }

  lastDayOfWeek(date = this.today) {
    date = this.parse(date);
    const firstDayOfWeek = this.firstDayOfWeek(date);
    return this.addDays(6, firstDayOfWeek);
  }

  generateDaysFromDate(n_days = 1, date = this.today, withInfo: boolean = false) {
    date = this.parse(date);
    const days = [];
    for (let i = 0; i < n_days; i++) {
      const dayDate = this.addDays(i, date);
      let v;
      if (withInfo) {
        v = this.dateInfo(dayDate);
      } else {
        v = this.parse(dayDate);
      }
      days.push(v);
    }
    return days;
  }

  generateDaysFromDates(date1: Date | string, date2: Date | string, withInfo: boolean = false) {
    if (date1 && date2) {
      date1 = this.parse(date1);
      date2 = this.parse(date2);
      const nDays = this.numberOfDays(date1, date2, true);
      const dayToStartFrom = this.parse(Math.min(date1.getTime(), date2.getTime()));
      return this.generateDaysFromDate(nDays, dayToStartFrom, withInfo);
    } else {
      return [];
    }
  }

  dateLabel(date = this.today) {
    date = this.parse(date);
    return this._datePipe.transform(date, 'EEEE');
  }

  monthLabel(date = this.today) {
    date = this.parse(date);
    return this._datePipe.transform(date, 'MMMM');
  }

  monthAbbr(date = this.today) {
    date = this.parse(date);
    return this._datePipe.transform(date, 'MMM');
  }

  dateLetter(date = this.today) {
    date = this.parse(date);
    return this.dateLabel(date).substring(0, 1);
  }

  monthLetter(date = this.today) {
    date = this.parse(date);
    return this.monthLabel(date).substring(0, 1);
  }

  indexOfDayByIndex(index = 1, firstDayOfWeekIndex = this.firstDayOfWeekIndex) {
    index = this.fromFluidDayIndex(index);
    return (DAYS_IN_WEEK - firstDayOfWeekIndex + index) % DAYS_IN_WEEK;
  }

  dayOfWeekByIndex(index = 1, date = this.today, firstDayOfWeekIndex = this.firstDayOfWeekIndex) {
    const daysDiff = this.indexOfDayByIndex(index, firstDayOfWeekIndex);
    const firstDayOfWeek = this.firstDayOfWeek(date);
    return this.addDays(daysDiff, firstDayOfWeek);
  }

  addDays(n_days = 1, date = this.today) {
    date = this.parse(date);
    return this.parse(date.setDate(date.getDate() + n_days));
  }

  addHours(n_hours = 1, date = this.today) {
    date = this.parse(date);
    return this.parse(date.setHours(date.getHours() + n_hours));
  }

  // SPECIFIC TO EXTERNAL COMPONENTS
  dateInfoByIndex(index = 1, date = this.today) {
    const indexDate = this.dayOfWeekByIndex(index, date);
    return this.dateInfo(indexDate);
  }

  // SPECIFIC TO EXTERNAL COMPONENTS
  monthInfoByMonthIndex(index = 0) {
    const date = this.firstDayOfMonth();
    date.setMonth(index);
    console.log('month test', date);
    return this.monthInfo(date);
  }

  dateInfo(date: Date | string = this.today) {
    date = this.parse(date);
    const dayInfo: DateInfoInterface = {
      weekday: this.toFluidDayIndex(this.dayIndex(date)),
      date: date,
      label: this.dateLabel(date),
      letter: this.dateLetter(date),
    };
    return dayInfo;
  }

  monthInfo(date: Date | string = this.today) {
    date = this.parse(date);
    const monthInfo: MonthInfoInterface = {
      index: date.getMonth(),
      date: date,
      label: this.monthLabel(date),
      letter: this.monthLetter(date),
      abbr: this.monthAbbr(date),
    };
    return monthInfo;
  }

  monthsArray() {
    const from = startOfYear(new Date());
    const months = [];
    for (let i = 0; i < 12; i++) {
      const month = addMonths(from, i);
      months.push(this.monthInfo(month));
    }
    return months;
  }

  weekArray(date = this.today) {
    date = this.parse(date);
    const dates = [];
    const firstDayOfWeek = this.firstDayOfWeek(date);
    const days = this.generateDaysFromDate(DAYS_IN_WEEK, firstDayOfWeek);
    for (const day of days) {
      dates.push(this.dateInfo(day));
    }
    return dates;
  }

  daysMap(dates = this.weekArray()) {
    const map = {};
    for (const date of dates) {
      map[date.weekday] = date;
    }
    return map;
  }

  monthsMap(months = this.monthsArray()) {
    const map = {};
    for (const month of months) {
      map[month.index] = month;
    }
    return map;
  }

  weekHoursArray(date = this.today) {
    date = startOfWeek(date, {
      weekStartsOn: 1,
    });
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = addDays(date, i);
      const hours = [];
      for (let i = 0; i < 24; i++) {
        const time = addHours(new Date(day), i);
        hours.push(time);
      }
      week.push({
        day: day,
        hours: hours,
      });
    }
    return week;
  }
}

export interface DateNameInterface {
  label?: any;
  letter?: any;
}

export interface DateInfoInterface extends DateNameInterface {
  weekday?: number;
  date?: Date;
}

export const DAYS_LIST_CONST = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export interface MonthInfoInterface {
  enabled?: boolean;
  date?: Date;
  index?: number;
  label?: string;
  letter?: string;
  abbr?: string;
}

