import { Injectable } from '@angular/core';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LS_GLOBALS_TIME } from 'app/modules/global/localstorage';
import { TimepickerFormatEnum } from 'app/modules/common/datetime/datetime.interface';
import { CachedParamsService } from './cached-params.service';

/**
 * Current user
 */
@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {

  constructor(
    private _i18nService: I18nService,
    private _cachedParamsService: CachedParamsService,
  ) {

  }

  get timeFormat() {
    const userGlobalsTime = this._cachedParamsService.getCachedParams(LS_GLOBALS_TIME);
    let format = userGlobalsTime.format;
    if (!format) {
      if (this._i18nService.currentLanguageCode === 'en') {
        format = TimepickerFormatEnum.h12;
      } else {
        format = TimepickerFormatEnum.h24;
      }
      // this._cachedParamsService.cacheParams(LS_GLOBALS_TIME, {
      //   ...userGlobalsTime,
      //   format
      // });
    }
    return format;
  }

  get timeFormatLang() {
    return this.timeFormat === TimepickerFormatEnum.h12 ? 'en' : 'it';
  }

  setTimeFormat(value: TimepickerFormatEnum) {
    const userGlobalsTime = this._cachedParamsService.getCachedParams(LS_GLOBALS_TIME);
    this._cachedParamsService.cacheParams(LS_GLOBALS_TIME, {
      ...userGlobalsTime,
      format: value
    });
  }
}
