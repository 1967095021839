import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { PaginatedResponse } from 'app/models/pagination.model';
import { SystemInterface } from 'app/models/system.model';
import { HISTORY_MAX } from 'app/modules/global/constants';
import { LS_HISTORY } from 'app/modules/global/localstorage';
import { CircuitRepositoryService } from 'app/repositories/circuit-repository.service';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, of, zip } from 'rxjs';
import { first } from 'rxjs/operators';

export interface HistoryLSMap {
  [key: string]: string[],
}

export interface HistoryItemMap {
  [key: string]: any[],
}

export interface HistoryMappedMap {
  [key: string]: { [id: string]: number },
}

export enum HistoryEnum {
  system = 'system',
  systemAnalytics = 'systemAnalytics',
  circuit = 'circuit',
  user = 'user',
  customer = 'customer',
}

interface HistoryItem {
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {

  protected lsKey = LS_HISTORY;
  protected _arrays: HistoryItemMap = {};
  protected _maps: HistoryMappedMap = {};

  history: HistoryItemMap = {};
  historyChange: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    protected _toastrService: ToastrService,
    protected _i18nService: I18nService,
    protected _systemRepositoryService: SystemRepositoryService,
    protected _circuitRepositoryService: CircuitRepositoryService,
    protected _userRepositoryService: UserRepositoryService,
    protected _customerRepositoryService: CustomerRepositoryService,
    protected _authenticationService: AuthenticationService,
    protected _currentUser: CurrentUserService,
  ) {
    this._init();
    this._currentUser.onRefresh.subscribe((data) => {
      this._init();
    });
  }

  getHistory(type: HistoryEnum) {
    return !!this.history && this.history[type] || [];
  }

  add(type: HistoryEnum, item: HistoryItem) {
    if (typeof item === 'object' && item.id) {
      if (!Array.isArray(this._arrays[type])) {
        this._arrays[type] = [];
      }
      this._arrays[type].unshift(item.id);
      this._arrays[type] = this._arrays[type].slice(0, HISTORY_MAX);
      this._mapArrays();
      if (!Array.isArray(this.history[type])) {
        this.history[type] = [];
      }
      this.history[type].unshift(item);
      this.history[type] = this.history[type].slice(0, HISTORY_MAX);
      this._save();
    }
  }

  remove(type: HistoryEnum, item: HistoryItem) {
    if (typeof item === 'object' && item.id) {
      // console.log(this._arrays);
      if (Array.isArray(this._arrays[type])) {
        const idsIndex = this._arrays[type].findIndex(id => id === item.id);
        if (idsIndex !== -1) {
          // console.log('index to remove', idsIndex);
          this._arrays[type].splice(idsIndex, 1);
          this._mapArrays();
          this._save();
        }
      }
      if (Array.isArray(this.history[type])) {
        const favIndex = this.history[type].findIndex(v => v.id === item.id);
        if (favIndex !== -1) {
          // console.log('index to remove', favIndex);
          this.history[type].splice(favIndex, 1);
        }
      }
    }
  }

  is(type: HistoryEnum, item: HistoryItem) {
    return !!this._maps[type] && typeof this._maps[type][item.id] !== 'undefined';
  }

  isLast(type: HistoryEnum, item: HistoryItem) {
    return Array.isArray(this._arrays[type]) && this._arrays[type].length && this._arrays[type][0] === item.id;
  }

  protected _fetchIds() {
    const callsMap = {};
    Object.keys(this._arrays).forEach(key => {
      const ids = this._arrays[key];
      switch (key) {
        case HistoryEnum.system:
        case HistoryEnum.systemAnalytics: {
          callsMap[key] = this._systemRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
          break;
        }
        case HistoryEnum.circuit: {
          callsMap[key] = this._circuitRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
          break;
        }
        case HistoryEnum.user: {
          callsMap[key] = this._userRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
          break;
        }
        case HistoryEnum.customer: {
          callsMap[key] = this._customerRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
          break;
        }
      }
    });

    return forkJoin(callsMap);
  }

  protected _mapArrays() {
    this._maps = {};
    Object.keys(this._arrays).forEach(key => {
      const ids = this._arrays[key];
      this._maps[key] = {};
      if (Array.isArray(ids)) {
        ids.forEach((id, index) => {
          this._maps[key][id] = index;
        });
      }
    });
  }

  protected _load() {
    this._arrays = localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : {};
    this._mapArrays();
  }

  protected _save(): void {
    localStorage.setItem(this.lsKey, JSON.stringify(this._arrays));
    this.historyChange.next(this.history);
  }

  protected _init() {
    // console.log('::: HISTORY INIT :::', this.lsKey, this);
    if (this._authenticationService.hasToken() && this._currentUser.hasUser() && this._currentUser.isEnabled()) {
      this._load();
      this._fetchIds().subscribe((results: { [key: string]: PaginatedResponse<any> }) => {
        Object.keys(results).forEach(key => {
          this.history[key] = results[key].items;
        });
        this.historyChange.next(this.history);
      });
    }
  }

  addRecord(type: HistoryEnum, item: { id?: string; }): void {
    // console.log('isLast', this.isLast(type, item));
    if (!this.isLast(type, item)) {
      this.remove(type, item);
      this.add(type, item);
    }
  }
}
