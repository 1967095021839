import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CustomerInterface } from 'app/models/customer.model';
import { FolderInterface } from 'app/models/folder.model';
import { Resource, ResourceStatus, ResourceTranscodeRequestInterface, ResourceTypeEnum } from 'app/models/resource.model';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { PAGE_SIZE_OPTIONS } from 'app/modules/global/constants';
import { REGEXP_FOLDER_NAME } from 'app/modules/global/regexps';
import { FolderRepositoryService } from 'app/repositories/folder-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { FolderService } from 'app/modules/folder/folder.service';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { LS_FOLDER_RESOURCES_SELECTOR, LS_RESOURCES_SELECTOR_VERSION } from 'app/modules/global/localstorage';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { ComparableSelectionModel } from 'app/modules/global/classes/comparable-selection-model';
import { ResourcesRepositoryService } from 'app/repositories/resources-repository.service';
import { S3FileItem } from 'app/modules/uploader/s3';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { PaginatedResponse } from 'app/models/pagination.model';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { ScreenInterface } from 'app/modules/system/components/screens/screens.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ResourceDialogService } from 'app/modules/resources/services/resource.dialog.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { ResourceConstraintInterface } from 'app/modules/resources/components/resource-constraints/resource-constraints.model';

export interface FolderResourceSelectorModelInterface {
  cid?: string;
  folderId?: string;
  query?: string;
  types?: ('images' | 'videos')[]; // ResourceTypeEnum[];
  sizes?: ScreenInterface;
  ascending?: boolean;
  // resources?: Resource[];
}

export interface FolderResourceSelectorOptionsInterface {
  allowedTypes?: ('images' | 'videos')[];
  allowedSizes?: ScreenInterface[];
  multiple?: boolean;
  allowUpload?: boolean;
  excludedIdsMap?: any;
}

export interface FolderResourcesSelectorDialogDataInterface {
  model?: FolderResourceSelectorModelInterface,
  options?: FolderResourceSelectorOptionsInterface,
}

export interface FolderResourcesRemoveSystemsDialogDataInterface {
  resources?: Resource[];
}

export enum FolderMenuSectionEnum {
  recent = 'recent',
  selected = 'selected',
}

@Component({
  selector: 'app-folder-resources-selector-dialog',
  templateUrl: './folder-resources-selector-dialog.component.html',
  styleUrls: ['./folder-resources-selector-dialog.component.scss'],
})
export class FolderResourcesSelectorDialogComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  loadingCustomer: boolean = false;

  loadingResources: boolean = false;
  resourcesList: PaginatedResponse<Resource>;

  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  FolderMenuSectionEnum = FolderMenuSectionEnum;

  selectedModel: ComparableSelectionModel<Resource>;

  selectedResourceTypes: ('images' | 'videos')[] = []; // [] --> all
  selectedResourceTypesDisabled: boolean;
  resourceTypes = [
    {
      label: this._i18nService.translate(_('Immagini')),
      value: ResourceTypeEnum.images,
      icon: 'image',
    },
    {
      label: this._i18nService.translate(_('Video')),
      value: ResourceTypeEnum.videos,
      icon: 'videocam',
    },
  ];

  showUploader: boolean = false;
  isUploaderUsable: boolean = true;
  isUploading: boolean = false;

  onParametersChange: Subject<any> = new Subject();

  defaults: FolderResourceSelectorModelInterface;

  uploadCallback: (item: S3FileItem) => Observable<Resource>;

  constraint: ResourceTranscodeRequestInterface;

  permissions: NgxPermissionsObject;

  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<FolderResourcesSelectorDialogComponent>,
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    private _folderRepositoryService: FolderRepositoryService,
    private _cachedParamsService: CachedParamsService,
    private _customerRepositoryService: CustomerRepositoryService,
    private _folderService: FolderService,
    private _resourcesRepositoryService: ResourcesRepositoryService,
    private _ngxPermissionService: NgxPermissionsService,
    private _resourceDialogService: ResourceDialogService,
    private _currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: FolderResourcesSelectorDialogDataInterface = {
      model: {},
      options: {
        multiple: true,
        allowUpload: true,
        allowedTypes: [], //[ResourceTypeEnum.images],
        allowedSizes: [],
        // allowedSizes: [{
        //   width: 1920,
        //   height: 1080,
        // }]
        // allowedSizes: [{
        //   width: 1920,
        //   height: 1080,
        // }, {
        //   width: 1080,
        //   height: 1920,
        // }]
      }
    },
  ) {

    this._unsubscribeAll = new Subject();

    this.uploadCallback = (item: S3FileItem): Observable<Resource> => {
      return this._resourcesRepositoryService
        .create(
          {
            source: item.additionalParameter.key,
            noWorkflow: false,
            notPrivate: false,
            folder: this.currentParams.folderId,
            //folder: this.form.get('folder').value,
          },
          this.constraint,
        )
    };
  }

  openOldSelector() {
    console.log('openOldSelector');
    this._cachedParamsService.cacheParams(LS_RESOURCES_SELECTOR_VERSION, {
      version: 0,
    });

    // console.log(this.matDialogRef);
    // return;

    this._resourceDialogService.open(<any>{
      multi: this.multiple,
      screens: this.allowedSizes,
      types: this.allowedTypes,
      excludedIdsMap: this.excludedIdsMap,
      dynamic: false,
      replace: false,
      screen: null,
    }).afterClosed().subscribe((resources) => {
      console.log('OLD CLOSED', resources);
      this.matDialogRef.close(resources);
    });
  }

  form: FormGroup = new FormGroup({
    customer: new FormControl(),
    folder: new FormControl(),
    // resources: new FormControl(),
    folderSearchText: new FormControl(''),
    query: new FormControl(''),
    types: new FormControl([]),
    sizes: new FormControl(''),
    ascending: new FormControl(false),
  })

  get multiple() {
    return this.data.options?.multiple || false;
  }

  get allowUpload() {
    return this.data.options?.allowUpload || false;
  }

  get excludedIdsMap() {
    return this.data.options?.excludedIdsMap || {
      // '66f6bbb7f625c5529106e302': 1, // TEST
    };
  }

  get allowedSizes() {
    return this.data.options?.allowedSizes || [];
  }

  get allowedTypes() {
    return this.data.options?.allowedTypes || [];
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  getSelectedSizes() {
    if (!this.allowedSizes.length) {
      return []; // EMPTY --> ALL
      // return [{
      //   width: '*',
      //   height: '*',
      // }];
    } else {
      if (!this.form.get('sizes').value) {
        if (this.allowedSizes.length === 1) {
          return this.allowedSizes[0];
        }
        return this.allowedSizes;
      } else {
        return this.form.get('sizes').value;
      }
    }
  }

  loadFilterParams(params = this.defaultFilterParams) {
    this.form.get('query').setValue(
      params.query
    );
    this.form.get('types').setValue(
      params.types,
    );
    this.form.get('sizes').setValue(
      params.sizes,
    );
  }

  resetFilters(event) {

    // console.log(this.defaultFilterParamsString);
    // console.log(this.filterParamsString);
    this.filtersVisible = false;

    if (this.areFiltersChanged) {
      this.loadFilterParams(this.defaultFilterParams);
    }
  }

  get defaultFilterParams() {
    return {
      query: '',
      types: this.allowedTypes,
      sizes: this.allowedSizes.length === 1 ? this.allowedSizes[0] : '',
      // this.allowedSizes.length === 0 ? [{ "width": "*", "height": "*" }] : '', // this.allowedSizes,
    }
  }

  defaultFilterParamsString: string = JSON.stringify(this.defaultFilterParams);

  filterParamsString: string = '';

  filtersVisible: boolean = false;

  get areFiltersChanged() {
    return this.defaultFilterParamsString !== this.filterParamsString;
  }

  showFilters() {
    this.filtersVisible = true;
  }

  get filterParams() {
    return {
      // FILTERS
      query: this.form.get('query').value,
      types: this.form.get('types').value,
      sizes: this.form.get('sizes').value,
    }
  }

  get searchParams() {
    return {
      ...this.filterParams,
      // OVERRIDE TYPES
      types: JSON.stringify(this.filterParams.types),
      // OVERRIDE SIZES
      sizes: JSON.stringify(this.getSelectedSizes()),

      // PAGINATION
      page: this.paginator.pageIndex + 1,
      count: this.paginator.pageSize,
      // DEFAULTS
      ascending: this.form.get('ascending').value,
      status: ResourceStatus.USABLE,
      // [{"width":"*","height":"*"}] for all... check if [] does the same
    }
  }

  get currentParams() {
    return {
      cid: this.form.get('customer').value?.id,
      folderId: this.form.get('folder').value?.id || this.form.get('folder').value,
      ...this.filterParams,
    }
  }

  refreshResources() {
    this.filterParamsString = JSON.stringify(this.filterParams);

    // console.log(this.defaultFilterParamsString);
    // console.log(this.filterParamsString);

    this.saveParams();

    if (this.currentParams.cid && this.currentParams.folderId) {
      switch (this.currentParams.folderId) {
        case FolderMenuSectionEnum.selected: {
          // DO NOTHING
          break;
        }
        case FolderMenuSectionEnum.recent: {
          // CALL RECENT RESOURCES WITHOUT FOLDER
          console.log('// CALL RECENT RESOURCES WITHOUT FOLDER')
          this.loadResources();
          break;
        }
        default: {
          // CALL RESOURCES BY FOLDER
          console.log('// CALL RESOURCES BY FOLDER')
          this.loadResources();
          break;
        }
      }
    }
  }

  // get resources() {
  //   return this.data?.resources || [];
  // }

  now = new Date();

  async ngOnInit() {
    console.log('FolderResourcesSelectorDialogComponent', this);

    this.permissions = this._ngxPermissionService.getPermissions();

    this.defaults = {
      ...this.defaultFilterParams,
      ...this._cachedParamsService.getCachedParams(LS_FOLDER_RESOURCES_SELECTOR),
      ...this.data.model,
    };

    // OVERRIDE
    if (!this.allowedSizes.length) {
      this.defaults.sizes = '' as any;
    } else {
      if (this.allowedSizes.length === 1) {
        this.defaults.sizes = this.allowedSizes[0];
      } else {
        if (this.defaults.sizes && typeof this.defaults.sizes === 'object') {
          this.defaults.sizes = this.allowedSizes.find(v => v.width === this.defaults.sizes.width && v.height === this.defaults.sizes.height);
        }
      }
    }

    console.log('this.defaults', this.defaults);

    this.selectedModel = new ComparableSelectionModel<Resource>(this.multiple, [], true, (a: Resource, b: Resource) => a.id === b.id);

    setTimeout(() => {
      this.loadFilterParams(this.defaults as any);
      this.initCustomer();
    });

    // SET ALLOWED TYPES
    if (Array.isArray(this.allowedTypes) && this.allowedTypes.length > 0) {
      this.form.get('types').setValue(this.allowedTypes);
      this.selectedResourceTypesDisabled = true;
    }

    // REFRESH RESOURCES
    merge(
      // this.form.get('folder').valueChanges,
      this.form.get('query').valueChanges,
      this.form.get('types').valueChanges,
      this.form.get('sizes').valueChanges,
      this.paginator.page,
      this.form.get('ascending').valueChanges,
    ).pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(400),
    ).subscribe(data => {
      console.log('text processed', data);
      if (this.currentParams.folderId === FolderMenuSectionEnum.selected) {
        this.selectFolder(FolderMenuSectionEnum.recent);
      }
      this.refreshResources();
    });
  }

  get hasOneCustomer() {
    return this._customerRepositoryService.hasOneCustomer;
  }

  async initCustomer() {

    this.loadingCustomer = true;
    this.form.get('customer').setValue(
      await this._customerRepositoryService.getSelectedCustomer()
    );
    this.loadingCustomer = false;
    this.form.get('folder').setValue(this.defaults.folderId);
    this.onCustomerChange(this.form.get('customer').value);


    // if (this.defaults.cid) {
    //   this.form.get('folder').setValue(this.defaults.folderId);

    //   if (this.hasOneCustomer) {
    //     this.form.get('customer').setValue(
    //       this._currentUserService.getFirstCustomer()
    //     );
    //   } else {
    //     this.loadingCustomer = true;
    //     this._customerRepositoryService.get(this.defaults.cid).subscribe(customer => {
    //       this.form.get('customer').setValue(customer);
    //       this.loadingCustomer = false;
    //       this.onCustomerChange(customer);
    //     }, () => {
    //       this.loadingCustomer = false;
    //     });

    //   }

    // } else {
    //   this.selectFolder(FolderMenuSectionEnum.selected);
    //   this.onCustomerChange(null);
    // }


  }

  goToFolder(event: MouseEvent, resource: Resource) {
    event.stopPropagation();
    this.selectFolder(resource.folder)
  }

  loadResourcesSubscription: Subscription;

  loadResources() {
    this.loadingResources = true;

    if (this.loadResourcesSubscription) {
      this.loadResourcesSubscription.unsubscribe();
    }

    console.log('NEW PARAMS', this.searchParams);

    // this.loadingResources = false;
    // return;

    if (this.currentParams.folderId) {
      if (this.currentParams.folderId === FolderMenuSectionEnum.recent) {
        this.loadResourcesSubscription = this._folderRepositoryService.getResourcesByCustomer(this.currentParams.cid, this.searchParams).subscribe((data) => {
          this.resourcesList = data;
          // console.log(this.resourcesList);
          this.loadingResources = false;
        }, () => {
          this.loadingResources = false;
        });
      } else {
        this.loadResourcesSubscription = this._folderRepositoryService.getResources(this.currentParams.folderId, this.searchParams).subscribe((data) => {
          this.resourcesList = data;
          // console.log(this.resourcesList);
          this.loadingResources = false;
        }, () => {
          this.loadingResources = false;
        });
      }
    }

  }

  saveParams() {
    this._cachedParamsService.cacheParams(LS_FOLDER_RESOURCES_SELECTOR, this.currentParams, false);
  }

  async onCustomerChange(customer) {

    if (!customer) {
      this.form.get('folder').reset();
      this.form.get('folderSearchText').reset();
      this.selectFolder(FolderMenuSectionEnum.selected);
    }

    console.log('onCustomerChange', customer);
    this.saveParams();

    // this.onParametersChange.next({
    //   cid: this.currentParams.cid,
    // });
  }

  onFolderChange(folder) {
    if (folder) {
      this.selectFolder(folder);
    } else {
      this.selectFolder(FolderMenuSectionEnum.recent);
    }
  }

  selectFolder(folder) {

    console.log('selectFolder', folder);

    this.form.get('folder').setValue(folder);

    this.saveParams();

    this.resourcesList = null;
    this.paginator?.firstPage();
    this.resetUploader();
    this.refreshResources();
  }

  uploadStarted() {
    this.isUploading = true;
  }

  onAllResourcesUploaded() {
    setTimeout(() => {
      console.log('onAllResourcesUploaded', new Date().toISOString());
      this.isUploading = false;
      this.loadResources();
    }, 1000);
  }

  uploadedAll() {
    console.log('uploadedAll', new Date().toISOString());
  }

  resetUploader() {
    this.isUploaderUsable = false;
    this.showUploader = false;
    setTimeout(() => {
      this.isUploaderUsable = true;
    }, 100);
  }

  onResourceSearchChange(event) {
    this.paginator?.firstPage();
  }

  onResourceTypeChange(event) {
    console.log('onResourceTypeChange', event);
    this.paginator?.firstPage();
  }

  onResourceSizeChange(event) {
    this.paginator?.firstPage();
  }

  onDirectionChange(event) {
    this.form.get('ascending').setValue(
      !this.form.get('ascending').value
    );
  }

  onResourceToggle(event: MouseEvent, resource: Resource) {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.selectedModel.isSelected(resource) ? this.selectedModel.deselect(resource) : this.selectedModel.select(resource);

      console.log('this.selectedModel.selected', this.selectedModel.selected);
    });
  }

  onResourceCheckToggle(event: MatCheckboxChange, resouce: Resource) {
    if (event.checked) {
      this.selectedModel.select(resouce);
    } else {
      this.selectedModel.deselect(resouce);
    }
  }

  save() {
    FormUtils.validateAllFormFields(this.form);
    console.log('save', this.form);

    if (this.form.valid) {
      this.matDialogRef.close(
        // this.form.value
        this.selectedModel.selected
      );
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
