import { DataTransform } from 'app/models/data-transform';
import { DateService } from 'app/utils/services/date.service';
import { ISO_DATETIME_FORMAT_WITH_SECONDS, ISO_DATE_FORMAT, ISO_YEAR_MONTH_FORMAT } from '../constants';
import { FluidDatePipe } from '../pipes/fluidDate.pipe';

export abstract class DataTransformAbstractDown implements DataTransform {

  protected _fluidDatePipe: FluidDatePipe;

  /**
   *
   *
   * @param {*} object
   * @memberof DataTransformAbstractDown
   */
  transform(object: any) { }
  /**
   *
   *
   * @protected
   * @param {string} date
   * @returns
   * @memberof DataTransformAbstractDown
   */
  protected normalizeTime(date: string | Date) {
    if (date) {
      return this._fluidDatePipe.transform(DateService.parse(date), ISO_DATETIME_FORMAT_WITH_SECONDS);
    }
  }
  /**
   *
   *
   * @protected
   * @param {string} date
   * @returns
   * @memberof DataTransformAbstractDown
   */
  protected normalizeMonth(date: string | Date) {
    if (date) {
      return this._fluidDatePipe.transform(DateService.parse(date), ISO_YEAR_MONTH_FORMAT);
    }
  }
  /**
   *
   *
   * @protected
   * @param {string} time
   * @returns
   * @memberof DataTransformAbstractDown
   */
  protected normalizeDate(date: string | Date) {
    if (date) {
      return this._fluidDatePipe.transform(DateService.parse(date), ISO_DATE_FORMAT);
    }
  }
}
