<!-- RESOURCE -->
<div class="ellipsis" fxFlex="100" fxLayout="column" fxFlex *ngIf="resource">
  <header class="ellipsis" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="ellipsis" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <mat-icon *ngIf="resource.type === resourceType.videos">videocam</mat-icon>
      <mat-icon *ngIf="resource.type === resourceType.images">image</mat-icon>
      <span class="ellipsis">{{ resource.name }}</span>
    </h2>
    <button mat-icon-button (click)="bottomSheetRef.dismiss()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="resource-preview-container" fxFlex="100" fxLayout="row" fxLayout.xs="column">
    <div class="resource-preview-container-media" fxFlex="360px" fxFlex.gt-xs="100">
      <resource-mini-preview [resource]="resource"></resource-mini-preview>
    </div>
    <div class="ml-4 resource-preview-metadata px-12">

      <div class="mb-12 word-break-all-deep font-size-16">
        <strong>
          <app-resource-content [content]="resource" [name]="true"></app-resource-content>
        </strong>
      </div>

      <div class="mb-12 grey-400-fg">
        <span [matTooltip]="'MIME type' | translate">{{ metadata.contentType }}</span> - <span
          [matTooltip]="'Dimensione' | translate"><app-filesize [bytes]="head?.size"></app-filesize></span>
      </div>

      <div class="border-top pt-12 mb-12 font-weight-600 text-uppercase font-size-12">{{ 'Informazioni' | translate }}
      </div>

      <div *ngIf="isVideo" fxLayout="row" fxLayoutGap="8px" class="mb-12">
        <!-- DURATION -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Durata' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ metadata.duration | fluidSecondsToDuration }}
          </div>
        </div>
        <!-- FPS -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'FPS' | translate }}</small>
          <div class="ellipsis font-size-12">
            <app-fps [value]="metadata.fps"></app-fps>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" class="mb-12">
        <!-- RESOLUTION -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Risoluzione' | translate }}</small>
          <div class="ellipsis font-size-12">
            <app-resolution [width]="metadata.width" [height]="metadata.height"></app-resolution>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" class="mb-12">
        <!-- CREATED -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Creato' | translate }}</small>
          <div class="ellipsis font-size-12">
            <app-date [date]="resource.created_at" formatter="mediumDate"></app-date>
          </div>
          <div class="ellipsis font-size-12">
            {{ 'alle@@at 8 oclock' | translate }} <app-date [date]="resource.created_at"
              formatter="shortTime"></app-date>
          </div>
        </div>
        <!-- UPDATED -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Aggiornato' | translate }}</small>
          <div class="ellipsis font-size-12">
            <app-date [date]="resource.updated_at" formatter="mediumDate"></app-date>
          </div>
          <div class="ellipsis font-size-12">
            {{ 'alle@@at 8 oclock' | translate }} <app-date [date]="resource.updated_at"
              formatter="shortTime"></app-date>
          </div>
          <small class="ellipsis">
            <strong *ngIf="resource.updated_by">{{ 'da@@edited by' | translate }}
              {{ resource.updated_by.username }}</strong>
          </small>
        </div>
      </div>

      <!-- ORIGINAL METADATA -->
      <div *ngIf="!isVideo" class="border-top pt-12 mb-12 font-weight-600 text-uppercase font-size-12">
        {{ 'Metadata' | translate }}
      </div>
      <div *ngIf="isVideo" class="border-top pt-12 mb-12 font-weight-600 text-uppercase font-size-12">
        {{ 'Metadata originale' | translate }}
      </div>

      <div fxLayout="row wrap" fxLayoutGap="8px">
        <!-- FORMAT -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis mb-12">
          <small class="grey-400-fg">{{ 'Formato' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ metadata.container|| '--' }}
          </div>
        </div>
        <!-- CODEC -->
        <div *ngIf="isVideo" fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis mb-12">
          <small class="grey-400-fg">{{ 'Codec' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ metadata.codec || '--' }}
          </div>
        </div>
        <!-- BITRATE -->
        <div *ngIf="isVideo" fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis mb-12">
          <small class="grey-400-fg">{{ 'Bit Rate' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ (metadata.bit_rate | bitrate) || '--' }}
          </div>
        </div>
        <!-- SIZE -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis mb-12">
          <small class="grey-400-fg">{{ 'Dimensione' | translate }}</small>
          <div class="ellipsis font-size-12">
            <app-filesize [bytes]="head?.size"></app-filesize>
          </div>
        </div>
      </div>

      <div *ngIf="isVideo" fxLayout="row" fxLayoutGap="8px" class="mb-12">
        <!-- VIDEO STREAM -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Flussi video' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ (metadata.videoStream | bitrate) || '--' }}
          </div>
        </div>
        <!-- AUDIO STREAM -->
        <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
          <small class="grey-400-fg">{{ 'Flussi audio' | translate }}</small>
          <div class="ellipsis font-size-12">
            {{ (metadata.audioStream | bitrate) || '--' }}
          </div>
        </div>
      </div>

      <!-- TRANSCODED METADATA -->
      <ng-container *ngIf="isVideo">

        <div class="border-top pt-12 mb-12 font-weight-600 text-uppercase font-size-12">
          {{ 'Metadata transcodificato' | translate }}
        </div>

        <div fxLayout="row" fxLayoutGap="8px" class="mb-12">
          <!-- FORMAT -->
          <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Formato' | translate }}</small>
            <div class="ellipsis font-size-12">
              {{ transcodedMetadata.format || '--' }}
            </div>
          </div>
          <!-- CODEC -->
          <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Codec' | translate }}</small>
            <div class="ellipsis font-size-12">
              {{ transcodedMetadata.video_codec || '--' }}
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="8px" class="mb-12">
          <!-- BITRATE -->
          <div fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Bit Rate' | translate }}</small>
            <div class="ellipsis font-size-12">
              {{ (transcodedMetadata.bit_rate | bitrate) || '--' }}
            </div>
          </div>
          <!-- SIZE -->
          <div *ngIf="transcodedMetadata.size" fxFlex="calc(50% - 8px)" fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Dimensione' | translate }}</small>
            <div class="ellipsis font-size-12">
              <app-filesize [bytes]="transcodedMetadata?.size"></app-filesize>
            </div>
          </div>
        </div>

      </ng-container>


      <!-- FOLDER -->
      <ng-container *ngIf="!!resource.folder">
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="border-top pt-12 mb-12">
          <mat-icon class="orange-400-fg mr-12">folder</mat-icon>
          <div fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Nella cartella' | translate }}</small>
            <div class="h4 ellipsis">
              <strong>
                <a [routerLink]="'/folders/' + resource.folder.id + '/management'" (click)="$event.stopPropagation()"
                  target="_blank">{{ resource.folder.name }}</a>
              </strong>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- BRAND -->
      <ng-container *ngIf="!!resource.brand">
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="border-top pt-12 mb-12">
          <mat-icon class="blue-400-fg mr-12">shopping_bag</mat-icon>
          <div fxLayout="column" class="ellipsis">
            <small class="grey-400-fg">{{ 'Nel Brand' | translate }}</small>
            <div class="h4 ellipsis">
              <strong>
                <a [routerLink]="'/brands/' + resource.brand.id + '/upload'" (click)="$event.stopPropagation()"
                  target="_blank">{{ resource.brand.name }}</a>
              </strong>
            </div>
          </div>
        </div>
      </ng-container>

      <div fxLayout class="pt-24" fxLayoutAlign="center center">
        <a mat-raised-button target="_blank" href="{{ resource.publicAvailableSource }}">
          <mat-icon>cloud_download</mat-icon> {{ 'Scarica risorsa' | translate }}
        </a>
      </div>

      <!-- <app-resource-metadata *ngIf="hasMetadata" [metadata]="resource.metadata"
        [transcodedMetadata]="resource.transcodedMetadata" [head]="resource.head"></app-resource-metadata>
      <mat-list>
        <mat-divider *ngIf="hasMetadata"></mat-divider>
        <mat-list-item *ngIf="!!resource.folder">
          <strong fxFlex="50%">{{ 'Cartella' | translate }}</strong>
          <span class="text-right ellipsis" fxFlex>
            {{ resource.folder.name }}
          </span>
        </mat-list-item>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Creato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="resource.created_at" formatter="medium"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.updated_at">
          <strong fxFlex="50%">{{ 'Aggiornato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="resource.updated_at" formatter="medium"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.updated_by">
          <strong fxFlex="50%">{{ 'Aggiornato da' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ resource.updated_by.username }}
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.brand">
          <strong fxFlex="50%">{{ 'Brand' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ resource.brand.name }}
          </span>
        </mat-list-item>
      </mat-list> -->
    </div>
  </div>
</div>
<!-- DCONTENT -->
<div class="ellipsis" fxFlex="100" fxLayout="column" fxFlex *ngIf="dcontent">
  <header class="ellipsis" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="ellipsis" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <app-resource-icon [dcontent]="dcontent"></app-resource-icon>
      <span class="ellipsis">{{ dcontent.name }}</span>
    </h2>
    <button mat-icon-button (click)="bottomSheetRef.dismiss()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="resource-preview-container" fxFlex="100" fxLayout="row" fxLayout.xs="column">
    <div class="resource-preview-container-media" fxFlex="360px" fxFlex.gt-xs="100">
      <iframe class="dcontent-iframe-preview" src="https://dev-programmatic.fluidnext.com"
        title="W3Schools Free Online Web Tutorials"></iframe>
    </div>
    <div class="ml-4 resource-preview-metadata">
      <app-dcontent-metadata [dcontent]="dcontent"></app-dcontent-metadata>
      <mat-list>
        <mat-divider></mat-divider>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Creato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="dcontent.created_at" formatter="short"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Aggiornato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="dcontent.updated_at" formatter="short"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="dcontent.updated_by">
          <strong fxFlex="50%">{{ 'Aggiornato da' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ dcontent.updated_by.username }}
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="dcontent.template.brand">
          <strong fxFlex="50%">{{ 'Brand' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ dcontent.template.brand.name }}
          </span>
        </mat-list-item>
      </mat-list>
      <div fxLayout class="p-16" fxLayoutAlign="center center">
        <a [routerLink]="'/datasets/' + dcontent.id" (click)="$event.stopPropagation()" target="_blank">
          <button mat-raised-button>{{ 'Val al Set di Dati' | translate }}</button>
        </a>
      </div>
    </div>
  </div>
</div>