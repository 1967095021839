import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { NavbarVerticalStyle1Component } from './style-1.component';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from 'app/modules/global/global.module';



@NgModule({
  declarations: [
    NavbarVerticalStyle1Component
  ],
  imports: [
    GlobalModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    FuseNavigationModule,
    TranslateModule,
  ],
  exports: [
    NavbarVerticalStyle1Component
  ]
})
export class NavbarVerticalStyle1Module {
}
