<div class="resource-card-item mat-elevation-z2" fxLayout="column" id="{{ resource.id }}" [ngClass]="getMainClass()">
  <header (click)="choose()" fxFlex="50px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
    class="p-8 cursor-pointer ellipsis">
    <app-resource-status [resource]="resource"></app-resource-status>
    <span class="ellipsis" [matTooltip]="resource.name" matTooltipClass="app-tooltip-wide"
      [matTooltipPosition]="'above'">
      {{ resource.name }}<br />
      <small class="subheader"><app-date [date]="resource.updated_at" formatter="short"></app-date></small>
    </span>
  </header>
  <div fxFlex class="resource-card-content-container">
    <ng-container [ngSwitch]="!!resource.thumb">
      <div *ngSwitchCase="true" (click)="choose()" fxFlex class="image cursor-pointer" backgroundImage
        backgroundImageSrc="{{ resource.thumb }}"></div>
      <div *ngSwitchCase="false" fxFlex fxLayout fxLayoutAlign="center center">
        <mat-icon class="s-40 grey-400-fg" *ngIf="resource.type === resourceType.videos">videocam</mat-icon>
        <mat-icon class="s-40 grey-400-fg" *ngIf="resource.type === resourceType.images">image</mat-icon>
      </div>
    </ng-container>

    <!-- TRANSCODING PROGRESS -->
    <ng-container *ngIf="resource.status === ResourceStatus.TRANSCODING">
      <app-ring-chart [matTooltip]="'Transcoding ' + (resource.transcodingProgress || 0) + '%'"
        class="transcoding-progress" [width]="90" [borderWidth]="6"
        [value]="resource.transcodingProgress"></app-ring-chart>
    </ng-container>

    <!-- VIDEO FPS -->
    <ng-container *ngIf="resource.type === resourceType.videos && resource.metadata && resource.metadata.fps">
      <div matTooltip="FPS" class="resource-card-fps orange-500"
        [ngClass]="{ 'goodrate': resource.metadata.fps >= 60 }"><app-fps [value]="resource.metadata.fps"></app-fps>
      </div>
    </ng-container>
  </div>
  <footer fxFlex="40px" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
    <div fxLayout fxFlex class="ellipsis">
      <app-dropdown *ngIf="!isSelectable" [item]="resource" [hasStandalone]="false" [options]="resourceOptions"
        (optionClick)="resourceOptionClick.emit($event)"></app-dropdown>

      <button *ngIf="isSelectable" [matTooltip]="'Scarica' | translate" type="button" mat-icon-button
        (click)="$event.stopPropagation(); download()">
        <mat-icon>cloud_download</mat-icon>
      </button>

      <div class="ellipsis" (click)="choose()" fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
        <ng-container *ngIf="resource.metadata.duration">
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <mat-icon class="app-icon-xs grey-400-fg mr-4">timer</mat-icon>
            <small class="ellipsis">
              <app-duration [time]="resource?.metadata?.duration" [options]="{ hideEmpty: true }"></app-duration>
            </small>
          </div>
        </ng-container>
        <ng-container *ngIf="resource.metadata.height">
          <div class="ellipsis" fxLayout="row" fxLayoutAlign="flex-start center">
            <small class="ellipsis">{{ resource.metadata.width }}x{{ resource.metadata.height }}px</small>
          </div>
        </ng-container>
        <ng-container>
          <button type="button" mat-icon-button [matTooltip]="'Anteprima' | translate"
            [disabled]="!resource.publicWebSource && !resource.publicAvailableSource"
            (click)="$event.stopPropagation(); preview()">
            <mat-icon>zoom_in</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </footer>
</div>