import { Pipe, PipeTransform } from '@angular/core';


export const BitratePipeUnit = {
  auto: 'auto',
  byte: 'Bps',
  kilobyte: 'kBps',
  megabyte: 'MBps',
  bit: 'bps',
  kilobit: 'kbps',
  megabit: 'Mbps'
};

const magnitude = 1024;
const byte = 8;

@Pipe({
  name: 'bitrate'
})
export class BitratePipe implements PipeTransform {
  transform(value: number, unit: string = BitratePipeUnit.auto): string {

    if (isNaN(value)) return '';

    let transform;
    //Auto magnitude
    if (unit === BitratePipeUnit.auto) {
      switch (true) {
        case ((value / (magnitude * magnitude)) >= 1):
          unit = BitratePipeUnit.megabit;
          break;
        case ((value / (magnitude)) >= 1):
          unit = BitratePipeUnit.kilobit;
          break;
        default:
          unit = BitratePipeUnit.bit;
      }
    }
    switch (unit) {
      case BitratePipeUnit.bit:
        transform = value;
        break;
      case BitratePipeUnit.byte:
        transform = value / (byte);
        break;
      case BitratePipeUnit.kilobit:
        transform = value / (magnitude);
        break;
      case BitratePipeUnit.kilobyte:
        transform = value / (magnitude * byte);
        break;
      case BitratePipeUnit.megabit:
        transform = value / (magnitude * magnitude);
        break;
      case BitratePipeUnit.megabyte:
        transform = value / (magnitude * magnitude * byte);
        break;
      default:
        throw new Error(`BitratePipeUnit ${unit} does not exist`);
    }
    return Math.round(transform * 100) / 100 + ' ' + unit;
  }
}
