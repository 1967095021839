import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { ResourceConstraintInterface } from '../../components/resource-constraints/resource-constraints.model';
import { Resource, ResourceTranscodeRequest, ResourceTranscodeRequestInterface } from 'app/models/resource.model';
import { ResourceConstraintsService } from '../../components/resource-constraints/resource-constraints.service';

@Component({
  selector: 'app-resource-constraints-dialog',
  templateUrl: './resource-constraints-dialog.component.html',
  styleUrls: ['./resource-constraints-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceConstraintsDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('cmp') cmp;

  model: ResourceTranscodeRequestInterface;

  constructor(
    public matDialogRef: MatDialogRef<ResourceConstraintsDialogComponent>,
    private _i18nService: I18nService,
    private _toastrService: ToastrService,
    private _resourceConstraintsService: ResourceConstraintsService,
    @Inject(MAT_DIALOG_DATA) public data: { resource: Resource },
  ) {
  }

  ngOnInit(): void {
    this.model = new ResourceTranscodeRequest({
      constraints: this.resource.constraints,
      bitRateLimit: this.resource.bitRateLimit,
    });
  }

  ngAfterViewInit(): void {
    console.log('cmp', this.cmp);
  }

  get form(): FormGroup {
    return this.cmp?.form;
  }

  get resource(): Resource {
    return this.data.resource;
  }

  save() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.matDialogRef.close(this.form.value);
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }
}

