import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemInterface, System } from 'app/models/system.model';
import { SystemDataTransformUp } from './data-transforms/system.data.transform.up';
import { SystemDataTransformDown } from './data-transforms/system.data.transform.down';

@Injectable({
  providedIn: 'root',
})
export class SystemHttpInterceptor implements HttpInterceptor {
  private _rexp = new RegExp(/systems\/([0-9a-z]{24})/);
  /**
   *Creates an instance of SystemHttpInterceptor.
   * @param {SystemDataTransformUp} _systemDataTransformUp
   * @param {SystemDataTransformDown} _systemDataTransformDown
   * @memberof SystemHttpInterceptor
   */
  constructor(
    private _systemDataTransformUp: SystemDataTransformUp,
    private _systemDataTransformDown: SystemDataTransformDown,
  ) { }
  /**
   *
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof SystemHttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._rexp.test(req.url) || req.url.indexOf('/token') !== -1) {
      return next.handle(req);
    }

    //console.log('system data transform');

    if (req.method === 'POST' || req.method === 'PUT') {
      // const bodyCopy = Object.assign({}, req.body);
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._systemDataTransformDown.transform(<SystemInterface>bodyCopy),
      });
    }

    // console.log('TEST', req.method, req);

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        //console.log('response event', event);
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._systemDataTransformUp.transform(<SystemInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
