import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const AppSystemsWithCircuitsValidator = (control) => {
    const circuits = !!control.parent && control.parent.get('circuits').value || [];
    // console.log('validating systems', circuits, this.form);
    if (!circuits.length && (!Array.isArray(control.value) || control.value.length === 0)) {
        return Validators.required(control);
    }
    return null;
}

export const AppCircuitsWithSystemsValidator = (control) => {
    const systems = !!control.parent && control.parent.get('systems').value || [];
    // console.log('validating circuits', systems, this.form);
    if (!systems.length && (!Array.isArray(control.value) || control.value.length === 0)) {
        return Validators.required(control);
    }
    return null;
}

export function AppNumericRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        // Allow null values as valid
        if (value === null || value === undefined) {
            return null;
        }

        // Check if the value is a number and within range
        if (typeof value === 'number' && value >= min && value <= max) {
            return null;
        }

        // Return an error object if validation fails
        return { numericRange: { min, max, actual: value } };
    };
}