import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SystemHttpInterceptor } from '../system/http-interceptors/system.http.interceptor';
import { TriggerHttpInterceptor } from '../trigger/http-interceptors/trigger.http.interceptor';
import { FeedHttpInterceptor } from '../feed/http-interceptors/feed.http.interceptor';
import { FeedElementHttpInterceptor } from '../feed/http-interceptors/feed.element.http.interceptor';
import { DistributionHttpInterceptor } from '../distribution/http-interceptors/distribution.http.interceptor';
import { AdHttpInterceptor } from '../ad/http-interceptors/distribution.http.interceptor';
import { CustomerHttpInterceptor } from '../customer/pages/http-interceptors/customer.http.interceptor';
import { FolderHttpInterceptor } from '../folder/http-interceptors/folder.http.interceptor';
import { CircuitHttpInterceptor } from '../circuit/http-interceptors/circuit.http.interceptor';
import { UserHttpInterceptor } from '../user/http-interceptors/user.http.interceptor';

export const MODEL_HTTP_INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: SystemHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TriggerHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FeedHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FeedElementHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DistributionHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AdHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CustomerHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FolderHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CircuitHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UserHttpInterceptor, multi: true },
];
