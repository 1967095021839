import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { UserInterface } from 'app/models/user.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserDataTransformDown extends DataTransformAbstractDown {

  constructor(protected datePipe: DatePipe) {
    super();
  }

  transform(user: UserInterface): UserInterface {
    if (Array.isArray(user.customers)) {
      user.customers = <any>user.customers.map(v => v.id);
    }
    return user;
  }
}
