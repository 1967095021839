import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Injectable } from '@angular/core';
import { ResourceConstraintInterface, ResourceConstraintsMap } from './resource-constraints.model';
import { MatDialog } from '@angular/material/dialog';
import { ResourceConstraintsDialogComponent } from '../../dialogs/resource-constraints-dialog/resource-constraints-dialog.component';
import { Resource } from 'app/models/resource.model';

@Injectable({
  providedIn: 'root'
})
export class ResourceConstraintsService {

  ResourceConstraintsMap = ResourceConstraintsMap;

  constructor(private _matDialog: MatDialog) {

  }

  getFromExt(format: string): ResourceConstraintInterface {
    return this.ResourceConstraintsMap[format];
  }

  openDialog(resource?: Resource) {
    return this._matDialog.open(ResourceConstraintsDialogComponent, {
      panelClass: 'resource-constraints-dialog',
      data: {
        resource,
      }
    });
  }
}

