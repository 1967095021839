<span class="notification-button-component">
  <button [matBadge]="notificationsService.unreadCount ? notificationsService.unreadCount : ''" matBadgeColor="accent"
    [matTooltip]="'Notifiche' | translate" mat-icon-button [matMenuTriggerFor]="notificationsMenu">
    <mat-icon *ngIf="notificationsService.unreadCount"
      [class.bell-animation]="!menuTrigger?.menuOpen">notifications_active</mat-icon>
    <mat-icon *ngIf="!notificationsService.unreadCount">notifications</mat-icon>
  </button>
  <mat-menu #notificationsMenu="matMenu" class="notification-menu">
    <div class="notification-container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
      <ng-container *ngFor="let notification of notificationsService.notifications; let i = index">
        <div [class.yellow-50-bg]="!notification.read">
          <div mat-menu-item [disableRipple]="notification.read" (click)="onNotificationClick($event, notification)">
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
              <div fxLayout="column">
                <small class="grey-400-fg mb-4">
                  <app-timeago [matTooltip]="(notification.created_at | fluidDate: 'medium')"
                    [date]="notification.created_at"></app-timeago>
                </small>
                <div [class.font-weight-600]="!notification.read">
                  {{ notification.subject }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!notificationsService.notifications.length" mat-menu-item [disableRipple]="true" class="grey-400-fg">
        <div class="p-12 text-center">
          {{ 'nessuna notifica recente' | translate }}
        </div>
      </div>
      <div class="notification-show-all grey-100-bg">
        <div mat-menu-item [routerLink]="'/notifications'">
          <!-- <button mat-button [routerLink]="'/notifications'"> -->
          <div class="text-center accent-fg">{{ 'mostra tutte' | translate }}</div>
          <!-- </button> -->
        </div>
        <div mat-menu-item *ngIf="notificationsService.notifications.length > 0" (click)="readAll($event)">
          <div class="text-center accent-fg">{{ 'segna tutto come letto' | translate }}</div>
        </div>
      </div>
    </div>

  </mat-menu>
</span>