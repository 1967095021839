import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { FeedInterface } from 'app/models/feed.model';
import { FieldInterface } from 'app/modules/common/inputs/custom-fields/fields/fields.interface';
import { FluidDatePipe } from 'app/modules/global/pipes/fluidDate.pipe';

@Injectable({
  providedIn: 'root'
})
export class FeedDataTransformUp extends DataTransformAbstractUp {

  constructor(protected _fluidDatePipe: FluidDatePipe) {
    super();
  }
  /**
   *
   *
   * @param {FeedInterface} feed
   * @returns
   * @memberof FeedDataTransform
   */
  transform(feed: FeedInterface): FeedInterface {
    // if (feed.created_at) {
    //   feed.created_at = this.removeUTC(feed.created_at.toString());
    // }
    // if (feed.updated_at) {
    //   feed.updated_at = this.removeUTC(feed.updated_at.toString());
    // }
    feed.fields = this.normalizeFields(feed.fields);
    return feed;
  }
  /**
   *
   *
   * @protected
   * @param {FieldInterface[]} fields
   * @returns {FieldInterface[]}
   * @memberof FeedDataTransformUp
   */
  protected normalizeFields(fields: FieldInterface[]): FieldInterface[] {
    if (!Array.isArray(fields)) {
      return fields;
    }

    fields.map((field: FieldInterface) => {

      // // Formly v6
      // field.props = field.templateOptions;
      // delete field.templateOptions;

      if (field.type === 'input' && (
        field.templateOptions.type === 'time' ||
        field.templateOptions.type === 'date'
      ) && field.defaultValue) {
        // console.log('>>> normalize field default date');
        field.defaultValue = this.removeUTC(field.defaultValue.toString());
      }
    });

    // console.log('FEED GET TRANSFORM', fields);

    return fields;
  }
}

