import { PusherBridgeService, PUSHER_CONFIG, PusherConfig } from 'app/modules/global/services/pusher.subscribe';
import { ResourcePreviewContentComponent } from './components/resource-preview/resource-preview-content.component';
import { ResourcePreviewComponent } from './components/resource-preview/resource-preview.component';
import { GlobalModule } from './../global/global.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { NgMaterialModule } from './../../ng-material/ng-material.module';
import { ResourceDialogSelectorDirective } from 'app/modules/resources/directives/resoruce-dialog-selector/resource-dialog-selector.directive';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesPickerComponent } from './components/resources-picker/resources-picker.component';
import { ResourceCardComponent } from './components/resource-card/resource-card.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ResourceMetadataComponent } from './components/resource-metadata/resource-metadata.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { ResourceMiniPreviewComponent } from './components/resource-mini-preview/resource-mini-preview.component';
import { ResourceStatusComponent } from './components/resource-status/resource-status.component';
import { ResourceThumbPreviewComponent } from './components/resource-thumb-preview/resource-thumb-preview.component';
import { ResourceIconComponent } from './components/resource-icon/resource-icon.component';
import { ResourcesDurationComponent } from './components/resource-duration/resources-duration.component';
import { DatetimeModule } from '../common/datetime/datetime.module';
import { ImageCommonModule } from '../common/images/image.common.module';
import { ResourceContentComponent } from './components/resource-content/resource-content.component';
import { StringModule } from '../common/string/string.module';
import { ResourceNestedThumbComponent } from './components/resource-nested-thumb/resource-nested-thumb.component';
import { ResourcesACLDialogComponent } from './dialogs/resources-acl-dialog/resources-acl-dialog.component';
import { AutocompleteModule } from '../common/autocompletes/autocomplete.module';
import { DcontentCardComponent } from './components/dcontent-card/dcontent-card.component';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../common/utils/ultils.module';
import { EntitiesSelectorModule } from '../common/autocompletes/entities-selector/entities-selector.module';
import { ResourceSettingsDialogComponent } from './dialogs/resource-settings-dialog/resource-settings-dialog.component';
import { DContentMetadataComponent } from './components/dcontent-metadata/dcontent-metadata.component';
import { ResourceSelectorComponent } from './components/resource-selector/resource-selector.component';
import { ResourceContentNestedComponent } from './components/resource-content-nested/resource-content-nested.component';
import { ResourceContentImageComponent } from './components/resource-content-image/resource-content-image.component';
import { ResourceContentVideoComponent } from './components/resource-content-video/resource-content-video.component';
import { ResourceContentDContentComponent } from './components/resource-content-dcontent/resource-content-dcontent.component';
import { ResourceConstraintsComponent } from './components/resource-constraints/resource-constraints.component';
import { ResourceConstraintsDialogComponent } from './dialogs/resource-constraints-dialog/resource-constraints-dialog.component';

@NgModule({
  declarations: [
    ResourceIconComponent,
    ResourceDialogSelectorDirective,
    ResourcesPickerComponent,
    ResourceCardComponent,
    DcontentCardComponent,
    ResourcePreviewComponent,
    ResourcePreviewContentComponent,
    ResourceMetadataComponent,
    DContentMetadataComponent,
    ResourceMiniPreviewComponent,
    ResourceStatusComponent,
    ResourcesDurationComponent,
    ResourceThumbPreviewComponent,
    ResourceContentComponent,
    ResourceNestedThumbComponent,
    ResourceSettingsDialogComponent,
    ResourcesACLDialogComponent,
    ResourceSelectorComponent,
    ResourceContentNestedComponent,
    ResourceContentImageComponent,
    ResourceContentVideoComponent,
    ResourceContentDContentComponent,
    ResourceConstraintsComponent,
    ResourceConstraintsDialogComponent,
  ],
  imports: [
    CommonModule,
    ImageCommonModule,
    NgMaterialModule,
    DatetimeModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    GlobalModule,
    ReactiveFormsModule,
    FileUploadModule,
    AngularDraggableModule,
    StringModule,
    AutocompleteModule,
    RouterModule,
    UtilsModule,
    EntitiesSelectorModule,
  ],
  exports: [
    ImageCommonModule,
    ResourceIconComponent,
    ResourceDialogSelectorDirective,
    ResourcesPickerComponent,
    ResourceCardComponent,
    DcontentCardComponent,
    ResourcePreviewComponent,
    ResourcePreviewContentComponent,
    ResourceMetadataComponent,
    DContentMetadataComponent,
    ResourceMiniPreviewComponent,
    ResourcesDurationComponent,
    ResourceStatusComponent,
    ResourceThumbPreviewComponent,
    ResourceContentComponent,
    ResourceNestedThumbComponent,
    ResourceSelectorComponent,
    ResourceContentNestedComponent,
    ResourceContentImageComponent,
    ResourceContentVideoComponent,
    ResourceContentDContentComponent,
    ResourceConstraintsComponent,
    ResourceConstraintsDialogComponent,
  ]
})
export class ResourcesModule {
  constructor(private _PusherBridgeService: PusherBridgeService) { }
  static forRoot(config: PusherConfig): ModuleWithProviders<ResourcesModule> {
    return {
      ngModule: ResourcesModule,
      providers: [{ provide: PUSHER_CONFIG, useValue: config }],
    };
  }
  static forTest(): ModuleWithProviders<ResourcesModule> {
    return {
      ngModule: ResourcesModule,
      providers: [
        {
          provide: PUSHER_CONFIG,
          useValue: <PusherConfig>{ id: '123456489', cluster: 'mt1', forceTLS: true, channel: 'mychannel' },
        },
      ],
    };
  }
}
