import { PipeTransform, Pipe, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { I18nService } from 'app/services/i18n.service';
import { TimeFormatService } from 'app/core/auth/services/time-format.service';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'fluidDate',
  pure: false,
})
export class FluidDatePipe implements PipeTransform {
  constructor(
    private _i18nService: I18nService,
    private _datePipe: DatePipe,
    private _timeFormatService: TimeFormatService,
  ) { }

  // https://angular.io/api/common/DatePipe
  transform(value: any, pattern: string = 'shortDate'): any {
    const timezone = undefined;
    // return this._datePipe.transform(value, pattern, timezone, this._i18nService.currentLanguageCode);
    return this._datePipe.transform(value, pattern, timezone, this._timeFormatService.timeFormatLang);
  }
}
