import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { TriggerInterface } from 'app/models/trigger.model';
import {
  TriggerContentsItemInterface,
  TriggerPeriodicalInterface,
  TriggerTimeInterface,
  TriggerTimeScheduleInterface,
} from '../../trigger.interface';
import { TimeslotMinutesItemInterface, TimeslotInterface } from 'app/modules/common/timeslot/timeslot.interface';
import { DatasetInterface } from 'app/models/dataset.model';
import { Resource } from 'app/models/resource.model';
import { FluidDatePipe } from 'app/modules/global/pipes/fluidDate.pipe';

@Injectable({
  providedIn: 'root',
})
export class TriggerDataTransformDown extends DataTransformAbstractDown {

  constructor(protected _fluidDatePipe: FluidDatePipe) {
    super();
  }
  /**
   *
   *
   * @param {TriggerInterface} trigger
   * @returns
   * @memberof TriggerDataTransform
   */
  transform(trigger: TriggerInterface): TriggerInterface {
    if (trigger.startAt) {
      trigger.startAt = this.normalizeDate(trigger.startAt.toString());
    }
    if (trigger.endAt) {
      trigger.endAt = this.normalizeDate(trigger.endAt.toString());
    }
    trigger.contents = this.normalizeContents(trigger.contents);
    console.log('transform down trigger', trigger);
    return trigger;
  }
  /**
   *
   *
   * @protected
   * @param {TriggerContentsItemInterface[]} contents
   * @returns {TriggerContentsItemInterface[]}
   * @memberof TriggerDataTransformDown
   */
  protected normalizeContents(contents: TriggerContentsItemInterface[]): TriggerContentsItemInterface[] {
    if (!Array.isArray(contents)) {
      return contents;
    }
    contents.map((item: TriggerContentsItemInterface) => {
      this.normalizeTimeslots(item.timeslots);
      this.normalizePeriodical(item.periodical);
      this.normalizeBehaviorTime(item.time);

      if (Array.isArray(item.dcontents)) {
        item.dcontents = item.dcontents.map((dcontent) => {
          return <DatasetInterface>{ id: dcontent.id };
        });
      }

      if (Array.isArray(item.resources)) {
        item.resources = item.resources.map((resource) => {
          return <Resource>{ id: resource.id };
        });
      }

      console.log('normalizzing trigger contents', item);
    });
    return contents;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerPeriodical} periodical
   * @returns {TriggerPeriodical}
   * @memberof TriggerDataTransformDown
   */
  protected normalizePeriodical(periodical: TriggerPeriodicalInterface): TriggerPeriodicalInterface {
    if (periodical) {
      this.normalizePeriodicalTimes(periodical.times);
    }
    return periodical;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerTimeInterface} time
   * @returns {TriggerTimeInterface}
   * @memberof TriggerDataTransformDown
   */
  protected normalizeBehaviorTime(time: TriggerTimeInterface): TriggerTimeInterface {
    if (time) {
      this.normalizeBehaviorTimeSchedules(time.schedules);
    }
    return time;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerTimeScheduleInterface[]} schedules
   * @returns {TriggerTimeScheduleInterface[]}
   * @memberof TriggerDataTransformDown
   */
  protected normalizeBehaviorTimeSchedules(schedules: TriggerTimeScheduleInterface[]): TriggerTimeScheduleInterface[] {
    if (!Array.isArray(schedules)) {
      return schedules;
    }
    schedules.map((item: TriggerTimeScheduleInterface) => {
      item.time = this.normalizeTime(item.time.toString());
    });
    return schedules;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotMinutesItemInterface[]} times
   * @returns {TimeslotMinutesItemInterface[]}
   * @memberof TriggerDataTransformDown
   */
  protected normalizePeriodicalTimes(times: TimeslotMinutesItemInterface[]): TimeslotMinutesItemInterface[] {
    if (!Array.isArray(times)) {
      return times;
    }
    times.map((item: TimeslotMinutesItemInterface) => {
      this.normalizeTimeslot(item.timeslots);
    });
    return times;
  }

  /**
   *
   *
   * @private
   * @param {TimeslotInterface[]} timeslots
   * @returns
   * @memberof TriggerDataTransformDown
   */
  protected normalizeTimeslots(timeslots: TimeslotInterface[]): TimeslotInterface[] {
    if (!Array.isArray(timeslots)) {
      return timeslots;
    }
    timeslots.map((timeslot: TimeslotInterface) => {
      this.normalizeTimeslot(timeslot);
    });
    return timeslots;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotInterface} timeslot
   * @returns {TimeslotInterface}
   * @memberof TriggerDataTransformDown
   */
  protected normalizeTimeslot(timeslot: TimeslotInterface): TimeslotInterface {
    if (!timeslot) return timeslot;

    if (timeslot.from) {
      timeslot.from = this.normalizeTime(timeslot.from.toString());
    }
    if (timeslot.to) {
      timeslot.to = this.normalizeTime(timeslot.to.toString());
    }
    return timeslot;
  }
}
