import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { FeedElementInterface } from '../../pages/feed.item.element/feed.item.element.interface';
import { FieldInterface } from 'app/modules/common/inputs/custom-fields/fields/fields.interface';
import { FluidDatePipe } from 'app/modules/global/pipes/fluidDate.pipe';

@Injectable({
  providedIn: 'root'
})
export class FeedElementDataTransformDown extends DataTransformAbstractDown {

  constructor(protected _fluidDatePipe: FluidDatePipe) {
    super();
  }
  /**
   *
   *
   * @param {FeedElementInterface} feed
   * @returns
   * @memberof FeedDataTransform
   */
  transform(el: FeedElementInterface): FeedElementInterface {
    console.log('elelele', el);
    this.normalizeFields(el.feed.fields, el);
    return el;
  }
  /**
   *
   *
   * @protected
   * @param {FieldInterface[]} contents
   * @returns {FieldInterface[]}
   * @memberof FeedDataTransformDown
   */
  protected normalizeFields(contents: FieldInterface[], element: FeedElementInterface): FieldInterface[] {
    if (!Array.isArray(contents)) {
      return contents;
    }
    contents.map((field: FieldInterface) => {
      if (field.type === 'input' && element.data && element.data[field.key]) {
        if (field.templateOptions.type === 'date') {
          console.log('>>> UPLOAD element date transform');
          element.data[field.key] = this.normalizeTime(element.data[field.key].toString());
        }
        if (field.templateOptions.type === 'month') {
          element.data[field.key] = this.normalizeTime(element.data[field.key].toString());
        }
        if (field.templateOptions.type === 'time') {
          element.data[field.key] = this.normalizeTime(element.data[field.key].toString());
        }
      }
    });
    return contents;
  }
}

