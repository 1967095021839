<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'Modifica parametri Video' | translate }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-0 m-0 ellipsis" mat-dialog-content>
        <div class="ellipsis p-0" fxLayout="column" *isAdmin>
            <div class="p-12 px-24 grey-100-bg border-bottom mb-12">
                <app-resource-content [content]="resource" [thumb]="true" [modalPreview]="true" [icon]="true"
                    [details]="true" [showInlineTimerForVideos]="true"
                    [showBitrateForVideos]="true"></app-resource-content>
            </div>
            <div class="p-12 px-24">
                <app-resource-constraints #cmp [(model)]="model"></app-resource-constraints>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button (click)="matDialogRef.close()">
            Annulla
        </button>
        <button color="primary" type="button" mat-raised-button [disabled]="form?.invalid" (click)="save()">
            Conferma
        </button>
    </div>
</div>